// Indication de scroll sur la page
.icon-scroll,
.icon-scroll:before{
  position: absolute;
  left: 50%;
}

.icon-scroll{
  width: 35px !important;
  height: 65px;
  margin-left: -20px !important;
  top: 92%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 20px;
  padding-right: 0 !important;
  &.icon-noir{
    filter: brightness(0.5);
  }
  &.gauche{
    left: 20%;
  }
}

.icon-scroll:before{
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 3px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

.icon-scroll.icon-noir:before{
  filter: brightness(0.5);
}

@keyframes scroll{
0%{opacity: 1;}
100%{opacity: 0; transform: translateY(42px);}
}

@media screen and (max-width: 1500px){
  .icon-scroll.gauche{
   left: 10%;
  }
}

@media screen and (max-width: 900px){
  .icon-scroll{
    display:none !important;
  }
  .icon-scroll.always-show{
     display: block !important;
  }
}
@media screen and (max-height: 880px){
  .icon-scroll{
    display:none !important;
  }
  .icon-scroll.always-show{
     display: block !important;
   }
}


