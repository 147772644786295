.nousjoindre section {
  min-height: 100vh;
  height:auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.nousjoindre section.infos-carte {
  background-color: $bleu-lc;
}

.nousjoindre section.infos-carte .informations {
  margin: 0 150px 0 auto;
  z-index: 2;
  width: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nousjoindre section.infos-carte .informations div.titre {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nousjoindre section.infos-carte .informations div.titre img#doodle-rouge-vertical {
  height: 230px;
  width: auto;
}

.nousjoindre section.infos-carte .informations div.titre img#doodle-rouge-horizontal {
  display: none;
}

.nousjoindre section.infos-carte .informations div.titre h1 {
  line-height: 110%;
  margin: 0 0 0 30px;
}

.nousjoindre section.infos-carte .informations ul {
  width: 60%;
}

.nousjoindre section.infos-carte .informations ul li {
  list-style: url("../images/doodle-zigzag-rouge-small.svg");
}

.nousjoindre section.infos-carte .informations ul li img {
  display: none;
}

.nousjoindre section.infos-carte .informations ul li div.adresse div {
  margin-top: 20px;
}

.nousjoindre section.infos-carte .informations ul li div.adresse div span {
  font-weight: 700;
}

.nousjoindre section.infos-carte .informations ul li div.horaire div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nousjoindre section.infos-carte .informations ul li div.horaire div span {
  font-weight: 700;
}

.nousjoindre section.infos-carte .informations ul h2 {
  font-size: rem(50);
  margin: 20px 0;
}

.nousjoindre section.infos-carte .informations ul h2 span {
  position: relative;
  top: 15px;
}

.nousjoindre section.infos-carte .informations ul p {
  margin: 0;
}

.nousjoindre section div.map {
  width: 45%;
  background-color: #C9EAF1;
}

.nousjoindre section.formulaire-courriel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.nousjoindre section.formulaire-courriel .wrapper {
  max-width: 1200px;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nousjoindre section.formulaire-courriel .wrapper .titre{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.nousjoindre section.formulaire-courriel .wrapper .titre h2 {
  margin: 20px;
}

.nousjoindre section.formulaire-courriel .wrapper .titre img {
  width: 300px;
  height: auto;
}

.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group .form-control {
  background-color: transparent;
  border-radius: 0!important;
  border: 0;
  border-bottom: 2px solid black;
}
.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group textarea {
  min-height: 10em;
  resize: none;
  border: 2px solid black!important;
}
.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-form-html .rendered-form .erf-textarea{
  width: 100%;
}

.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group label .erf-required{
  color: #2B2B2B;
}

.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-submit-button {
  display: flex;
  justify-content: center;
}
.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-submit-button .erf-button button {
  min-height: 45px;
  padding: 0 100px;
  border: 2px solid #2B2B2B;
  font-family: 'Staatliches', sans-serif;
  font-size: 20px;
  background-color: transparent;
  color: #2B2B2B;
}
.nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-submit-button .erf-button button:hover {
  background-color: #2B2B2B;
  color: white;
  cursor: pointer;
}


@media screen and (max-width: 1300px) {
  .nousjoindre section.infos-carte .informations ul li div.horaire div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .nousjoindre section {
    height: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .nousjoindre section.infos-carte {
    background-color: #fff;
  }

  .nousjoindre section.infos-carte .informations {
    padding: 160px 0;
    margin: 0;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nousjoindre section.infos-carte .informations div.titre {
    width: 100%;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nousjoindre section.infos-carte .informations div.titre h1 {
    margin: 0;
    width: 70%;
  }

  .nousjoindre section.infos-carte .informations div.titre img#doodle-rouge-vertical {
    display: none;
  }

  .nousjoindre section.infos-carte .informations div.titre img#doodle-rouge-horizontal {
    display: block;
    width: 300px;
    height: auto;
    margin: 0 0 20px 0;
  }

  .nousjoindre section.infos-carte .informations ul {
    text-align: center;
    width: 100%;
    padding: 0;
  }

  .nousjoindre section.infos-carte .informations ul li {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 30px 0;
  }

  .nousjoindre section.infos-carte .informations ul li img {
    display: block;
    width: 60px;
    height: auto;
  }

  .nousjoindre section.infos-carte .informations ul li div.horaire div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nousjoindre section.infos-carte .informations ul h2 {
    margin: 20px;
  }

  .nousjoindre section.infos-carte .informations ul h2 span {
    top: 0;
  }

  .nousjoindre section.infos-carte div.map {
    height: 50vh;
    width: 100%;
  }

  .nousjoindre section.formulaire-courriel {
    padding: 50px 20px;
    background-color: #C9EAF1;
  }

  .nousjoindre section.formulaire-courriel .wrapper {
    max-width: 100%;
    padding: 0;
  }

  .nousjoindre section.formulaire-courriel .wrapper .titre img {
    width: 250px;
  }

  .nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-submit-button .erf-button {
    width: 100%!important;
  }

  .nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-submit-button .erf-button button {
    padding: 0;
    width: 100%;
  }
  .nousjoindre section.formulaire-courriel .erf-container .erf-form .erf-submit-button .erf-button button:hover {
    background-color: #2B2B2B;
    color: #C9EAF1;
  }

  .nousjoindre footer {
    overflow: hidden;
  }
}