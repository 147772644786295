.dons {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100% !important;
    margin: 0;
    height: fit-content;

    .wrapper {
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 100px;

      div.titre {

        h2 {
          margin-top: 70px;
          margin-bottom: 35px;
        }

        img {
          width: 270px;
          height: auto;
          margin-bottom: 40px;
        }

        p{
          text-align: left;
        }
      }

    }
  }
}

@media screen and (max-width: 900px) {

  .dons {
    section{
      .wrapper{
        margin: 0 15px;
        div.titre{


          img{
            width: 200px;
          }
        }
      }
    }
  }
}