.galerie{
  section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 0;
      .titre{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 25vh;
        min-height: 200px;
        padding-bottom: 40px;
        h1{
          padding: 20px 0;
          margin: 0;
        }
        img{
          width: 250px;
          height: auto;
        }
        
        p{

          padding: 0 20px;

          a{
            font-weight: $fw-bold;
            color: $rouge-fonce-lc;
            transition: 0.3s ease;
          }
          a:link{}
          a:hover{
            text-decoration: none;
            color: $rouge-fonce-lc-light
          }
        }
      }

      .images{
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        justify-content: center;
        .une-image{
          width: 25%;
          height: 33vh;
          padding: 10px;
          object-fit: cover;
          img{
            object-fit: cover;
            height: 100%;
          }
          .info{
            display: none;
          }
          &:hover{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              transition: all 0.3s;
              filter: brightness(0.6);
            }
            .info{
              text-align: center;
              height: 100%;
              width: 70%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              h3,h4{
                margin: 0;
                padding: 0;
                color: $white;
              }
              h4{
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  .une-image{
    &:hover{
      cursor: pointer;
    }
  }

  .modal-galerie{
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    height: 100vh;
    width: 100vw;
    background-color: rgba(43,43,43,0.8);

    .container-modal{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .close-modal{
        position: absolute;
        width: 22px;
        height: 22px;
        top: 20px;
        right: 20px;
        cursor: pointer;
        filter: invert(1);
        margin: 0;
      }
      img{
        height: 70vh;
        width: auto;
        max-height: 700px;
        max-width: 800px;
        object-fit: cover;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        width: 300px;
        height: 70vh;
        max-height: 700px;
        max-width: 800px;
        background: $white;

        h4 {
          p {
            font-family: $ff-normal;
            text-transform: none;
            letter-spacing: 0;
            font-size: 1rem;
            font-weight: 700;
            margin-top: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .galerie{
    section {
      .titre{
        padding-bottom: 40px;
        height: 20vh;
      }
     .images{
       .une-image{
        width: 33%;
         height: 33vh;
         min-height: 200px;
       }
     }
      &.section2{
        .images{
          .une-image{
            height: 25%;
          }
        }
      }


    }
    .modal-galerie{
        .container-modal{
          img{
            width: 60vw;
          }
          .info{
            width: 220px;
          }
        }
      }
  }
}



@media screen and (max-width: 900px){
  .galerie{
    section {
      padding: 50px 0 40px;
      border-bottom: 1px solid $white-lc;
      .titre{
        padding: 20px 0;
        min-height: 150px;
        height: 15vh;
      }
      .images{
        padding: 5px;
        .une-image{
          padding: 5px;
          width: 45vw;
          height: 45vw;
          min-height: 50px;
          &:hover{
              .info{
                display: none;
              }
          }
        }
      }
    }
    .modal-galerie{
      .container-modal{
        position: relative;
        flex-direction: column;
        .close-modal{
          position: fixed;
          top: 20px;
          right: 20px;
          color: $white-lc;
          filter: none;
        }
        img{
          width: 100vw;
          height: 100%;
          max-height: 60vh;
        }
        .info{
          width: 100vw;
          height: auto;
          p{
            margin: 7px 0;
          }
          h4{
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}



//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .galerie {
    section {

      .images{
        max-width: 100vw;

        .une-image{
          overflow: hidden;

          img{
            width: 100%;
            height: auto;
          }

          &:hover{

            justify-content: flex-start;
            align-items: flex-start;
            img{
              opacity: 0.4;
            }
            .info{
              text-align: left;
              margin-left: 30px;
              justify-content: center;
              align-items: flex-start;
              left:0;
              top:0;

              h3,h4{
                max-width: 25vw;
                color: black;
              }
              h4{
              }
            }
          }

        }

      }


    }
  }

}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 900px){
  .galerie {
    section {

      .titre{
        margin-bottom: 40px;
        
        p{
          max-width: 90vw;
          text-align: center;
        }
      }
    }
  }

}