//FICHIER DE CONFIGURATION POUR LES VARIABLES DE COULEUR

// Couleur de base 
$white:    #fff;
$white-lc: #ebe9eb;
$black:    #000;
$black-lc: #2B2B2B;
$bleu-lc: #CEC0D2;
$orange-lc: #E6EBBC;
$rouge-rose-lc: #DE836B;
$rouge-lc: #E29D8B;
$rouge-fonce-lc: #8C1B2F;

$color-text-normal: $black-lc;

$color-bg-page: $white;

$black-lc-light: #595757;
$black-lc-dark: #201A1A;
$bleu-lc-light: #E5DCE8;
$bleu-lc-dark: #BC92C8;
$orange-lc-light: #F7FADE;
$orange-lc-dark: #D6DE90;
$rouge-rose-lc-light: #E29D8B;
$rouge-rose-lc-dark: #CF6347;
$rouge-fonce-lc-light: #CF6347;
$rouge-fonce-lc-dark: #BF4221;

$color-link: $black-lc;
$color-link-visited: $color-link;
$color-link-hover: lighten($color-link, 25%);
$color-link-active: darken($color-link, 15%);
$color-link-focus: $color-link;


//Couleurs pour formulaire
$base-input-placeholder-color:                  #999 ;
$base-input-color:                              #000 ;
$base-input-background-color:                   #fff ;
$base-input-background-focus-color:             #fff ;
$base-input-border-color:                       #ccc ;
$base-input-border-focus-color:                 #000 ;



//Couleurs des médias sociaux
$color-facebook: #3b5999;
$color-messenger: #0084ff;
$color-twitter: #55acee;
$color-linkedin: #0077B5;
$color-skype: #00AFF0;
$color-dropbox: #007ee5;
$color-wordpress: #21759b;
$color-vimeo: #1ab7ea;
$color-slideshare: #0077b5;
$color-vk: #4c75a3;
$color-tumblr: #34465d;
$color-yahoo: #410093;
$color-googleplus: #dd4b39;
$color-pinterest: #bd081c;
$color-youtube: #cd201f;
$color-stumbleupon: #eb4924;
$color-reddit: #ff5700;
$color-quora: #b92b27;
$color-yelp: #af0606;
$color-weibo: #df2029;
$color-producthunt: #da552f;
$color-hackernews: #ff6600;
$color-soundcloud: #ff3300;
$color-blogger: #f57d00;
$color-whatsapp: #25D366;
$color-wechat: #09b83e;
$color-line: #00c300;
$color-medium: #02b875;
$color-vine: #00b489;
$color-slack: #3aaf85;
$color-instagram: #e4405f;
$color-dribbble: #ea4c89;
$color-flickr: #ff0084;
$color-foursquare: #f94877;
$color-behance: #131418;
$color-snapchat: #FFFC00;
