.mobile {
  display:none !important;
}

.displayOn {
  display:block !important;
}

@media screen and (max-width:900px) {

  .mobile {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .desktop {
    display:none !important;
  }

  .displayOn {
    display:flex !important;
  }
}