.cours {
  header.header-section {
    background-image: url(../images/bg-cours.png);
  }

  section {
    .wrapper {
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 100px;

      .titre {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      h2 {
        letter-spacing: rem(6);
        margin: 20px 0;
        text-align: center;
      }

      .contenu {
        margin: 50px 0;
        div {
          p {
            margin: 20px 0;
          }
          h3 {
            padding: 0;
            margin-top: 0;
          }

          div:nth-child(2) {
            h3 {
              margin-top: 22px;
            }

          }
        }
      }
    }

    &.mig-tig {
      background-color: $bleu-lc;
      display: flex;
      justify-content: center;
      position: relative;
      flex-direction: column;
      align-items: center;

      & > img.deco {
        width: auto;
        height: 40vh;
        top: 50vh;
        margin-top: -20vh;

        &.gauche {
          position: absolute;
          left: 0;
        }

        &.droite {
          position: absolute;
          right: 0;
          transform: rotate3d(0, 1, 0, 180deg);
        }
      }

      & > div.wrapperDeco {
        width: 100vw;
        justify-content: space-between;
        align-items: center;
        min-height: 20vh;
        position: relative;
        display: none;

        img.deco {
          width: auto;
          height: 20vh;
          top: 50vh;
          margin-top: -6vh;

          &.droite {
            transform: rotate3d(0, 1, 0, 180deg);
          }
        }
      }

      div.wrapper {
        div.titre {
          h2 {
            margin-top: 70px;
          }
          img {
            width: 300px;
            height: auto;
            margin: 0;
            padding: 10px 0;
          }
        }

        div.contenu {
          display: flex;
          margin-bottom: 70px;
          div {
            width: 50%;
            &:first-child {
              padding-right: 20px;
            }
            &:last-child {
              border-left: 10px solid transparent;
              border-image: url("../images/ligne-verticale-rouge.svg") 30 stretch;
              padding-left: 20px;
            }
          }
        }
      }
    }


    &.cours {
      background-color: $orange-lc;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;

      h2{
        margin: 15px 0;
      }

      & > img.deco {
        width: auto;
        height: 54vh;

        &.gauche {
          position: absolute;
          left: 0;
        }

        &.droite {
          position: absolute;
          right: 0;
          transform: rotate3d(0, 1, 0, 180deg);
        }
      }

      div {
        &.contenu {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h2 {
            text-align: center;
          }

          img {
            width: auto;
          }

          p {
            max-width: 1040px;
            text-align: center;
            margin-bottom: 20px;
            margin-top: 20px;
            padding: 0 15px;
          }

          div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 1320px;

            a {
              margin-left: 10px;
              margin-right: 10px;
              margin-bottom: 20px;
              text-decoration: none;

              div {
                height: 246px;
                width: 246px;
              }

              div.cours {
                background-size: cover;
                background: rgba(98, 91, 91, 0.6);
                background-blend-mode: multiply, normal;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;

                img {
                  margin-top: 20px;
                }

                h3 {
                  text-align: center;
                  max-width: 215px;
                  color: #ffffff;
                  margin: 0;
                  //font-size: rem(20);
                  font-size: rem(18);
                  letter-spacing: rem(2.5);
                  padding: 0 5px;

                  &:nth-child(2), &:nth-child(4) {
                    padding-top: 17px;
                    padding-bottom: 26px;
                    margin: 0;
                  }

                  .cours-nom {
                    //position: absolute;
                    //bottom:8px;
                    padding-bottom: 0;
                  }

                  &.cours-categorie, &.cours-special {
                    font-size: rem(15);
                  }

                  &.cours-special {
                    position: absolute;
                    bottom: 5px;
                  }
                }
                &:hover {
                  transition: all 0.2s;
                  filter: drop-shadow(2px 4px 6px $black-lc);
                }
              }
              &.plusCours{
                div{
                  width: 200px;
                }
              }
            }
          }
        }
      }
    }

    &.conditions-generales {
      background-color: $rouge-lc;
      display: flex;
      justify-content: center;
      position: relative;

      div.wrapper {

        margin-bottom: 70px;

        .titre {
          //h2{
          //  margin-top: 70px;
          //}
        }
        img {
          width: 300px;
          height: auto;
          margin: 0;
        }
        span {
          font-weight: $fw-bold;
          margin: 0;
        }
        h4 {
          text-align: center;
        }
        p {
          margin: 0;
        }

        div.contenu {
          display: flex;

          div {
            width: 50%;
            padding-right: 20px;
            ul {
              padding: 0 20px;
            }

            &#last-div-element {
              border-left: 10px solid transparent;
              border-image: url("../images/ligne-verticale-orange.svg") 30 stretch;
              padding: 0 0 0 20px;
            }

            div {
              padding: 0;
              width: 100%;
            }
          }
        }

      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .cours {
    section.cours {
      div.contenu{
        div{
          max-width: 1200px;
          a.plusCours{
            div{
              width: 180px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .cours {
    section.cours {
      & > img.deco {
        display: none;
      }
      div.contenu{
        div{
          a.plusCours{
            div{
              width: 160px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .cours {
    section {
      &.cours {
        h2{
          margin-bottom: 20px;
        }
        div.contenu {
          div {
            padding: 0 80px;
            a {
              div.cours {
                height: 200px;
                width: 200px;
                padding: 0;

                h3 {
                  width: 100%;
                  font-size: rem(16);
                  padding: 0 10px;
                  line-height: 24px;

                  &:nth-child(2), &:nth-child(4) {
                    font-size: rem(14);
                    padding-bottom: 12px;
                  }

                  &:nth-child(4) {
                  }
                }
              }
              &.plusCours{
                div{
                  width: 200px;
                  height: 190px;
                }
              }
            }
          }
        }
      }

      &.mig-tig {
        div.wrapper {
          div.titre {
            img {
              max-width: 200px;
            }
          }

          div.contenu {
            margin: 0;

            div {
              p {
                margin: 18px 0;
                font-size: rem(14);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2000px), screen and (min-height: 1200px) {
  .cours {
    section.mig-tig {
      & > img.deco {
        max-width: calc((100vw - 1200px) / 2);
        max-height: calc((100vw - 1200px) / 2 * 1.32);
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .cours {
    section.mig-tig > img.deco {
      display: none;
    }
  }
}

@media screen and (max-width: 900px) {
  .cours {
    section {
      height: inherit;

      .wrapper {
        max-width: 100%;
        margin: 0;
        padding: 50px 20px;

        .contenu {
          flex-direction: column;
        }
      }

      &.mig-tig {
        & > div.wrapperDeco {
          display: flex;
        }
        div.wrapper {

          div.titre {
            h2 {
              margin-top: 70px;
            }
          }
          div.contenu {
            margin: 0;
            div {
              width: 100%;
              &:first-child {
                padding: 0;
              }
              &:last-child {
                border-left: none;
                border-top: 10px solid transparent;
                border-image: url("../images/ligne-horizontale-rouge.svg") 30 stretch;
                padding: 20px 0 0 0;
              }
            }
          }
        }
      }

      &.cours {
        h2 {
          text-align: center;
          margin-top: 90px;
        }
        & > img.deco {
          width: 100%;
          height: auto;
          max-height: 120px;
          object-fit: contain;

          &.haut {
            position: absolute;
            top: 0;
          }

          &.bas {
            position: absolute;
            bottom: 0;
          }
        }

        p {
          margin-top: 24px !important;
          margin-bottom: 32px !important;
          max-width: calc(100vw - 32px) !important;
        }

        div {

          &.contenu {
            margin-top: 20px;

            & > div {
              max-width: calc(100vw - 32px);
              margin-bottom: 120px;
              padding: 0;
              a {
                div {
                  height: 164px;
                  width: 164px;

                  img {
                    margin-top: 8px;
                  }

                  h3 {
                    font-size: rem(14) !important;
                    line-height: 140%;
                    width: 142px;
                    letter-spacing: 0.18rem;

                    &:nth-child(2), &:nth-child(4) {
                      font-size: rem(12);
                    }

                    &:nth-child(2) {
                      padding-bottom: 12px !important;
                    }

                    &:nth-child(3) {
                      //position: absolute;
                      top: 50%;
                      transform: translateY(-35%);
                    }
                    &.cours-categorie {
                      margin-top: 8px;
                    }
                    &.cours-special {
                      bottom: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.conditions-generales {
        div.wrapper {
          margin-bottom: 0;
          div.contenu {
            margin: 30px 0;
            div {
              width: 100%;
              h3 {
                margin: 22px 0 !important;
              }

              &:first-child {
                padding: 0;
              }
              &#last-div-element {
                border-left: none;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .cours {
    section.cours {
      div.contenu{
        p{
          margin: 30px 5vw;
        }
        div{
          a{
            div.cours{
              height: 190px;
            }

          &.plusCours{
            div.cours{
              width: 160px;
            }
          }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 880px) {
  .cours {
    section:not(.video) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@media screen and (max-width: 475px) {
  .cours section.cours div.contenu div a {
    margin: 0 2vw 4vw;
    div.cours {
      width: 40vw;
      height: 60vw;
      h3 {
        padding: 0 5px;
        line-height: 18px;
        &.cours-nom {
          padding: 0 5px 5px;
          margin-top: 6vw;
        }
      }
    }
  }
}

//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .cours {

    section {

      &.cours{
        &>img.deco{
          top: 25vh;

        }
      }

      &.mig-tig {

        div.wrapper {
          div.paragrapheseul {
            p {
              max-width: 80vw;
            }
          }

        }
      }


      &.conditions-generales{
        div.wrapper{
          div.contenu{
            max-width: 80vw;
          }

        }

      }
    }

  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 900px) {
  .cours {

    section {

      &.mig-tig {

        div.wrapper {
          div.contenu {
            div {
              max-width: 42vw;
            }
          }


        }

      }
    }

  }

}



@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 900px) {
  .cours {

    section {

      &.mig-tig {

        div.wrapper {
          div.contenu {
            div {
              max-width: 90vw;
            }
          }

          div.paragrapheseul {
            p {
              max-width: 90vw;
            }
          }

        }

      }
    }

  }

}




@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 600px) {
  .cours {

    section {
      &.conditions-generales{
        div.wrapper{

          h2{
            max-width: 90vw;
          }

          div.contenu{
            max-width: 90vw;
          }
          h4{
            max-width: 90vw;
          }

          img{
            max-width: 90vw;
          }
        }

      }
    }

  }

}