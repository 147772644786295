//FICHIER DE CONFIGURATION POUR LES VARIABLES DE POLICE


/// Grosseur des textes
$fz-normal: rem(17);

$lh-normal: 25px;

/// Font family

$ff-normal: 'Space Mono', monospace;
$ff-titres: 'Staatliches', sans-serif;

/// Fonts weight
$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 500;
$fw-bold: 700;

@media screen and (max-width: 1500px){
  body{font-size: rem(16);}
}

@media screen and (max-width: 800px){
  body{font-size: rem(14);}
}
