.connexion{
  section {
    &.section1{
      display: flex;
      img{
        width: 40vw;
        height: 100vh;
        object-fit: cover;
      }
      .texte{
        width: 60vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 150px;
        .titre{
          position: relative;
          display: flex;
          img{
            position: absolute;
            left: -50px;
            height: 130px;
            width: auto;
          }
          img.mobile{
            display: none;
          }
        }
        p{
          max-width: 515px;
          a{
            color: $rouge-fonce-lc;
            font-weight: bold;
          }
        }
      }
    }

  }
}

@media screen and (max-width: 1200px){
  .connexion{
    section {
      &.section1{
        .texte{
          margin-left: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px){
  .connexion{
    section {
      &.section1{
        img{
          width: 30vw;
        }
        .texte{
          width: 70vw;
          p{
            margin: 0 20px 0 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px){
  .connexion{
    section {
      &.section1{
        background: $bleu-lc;
      }
    }
  }
}

@media screen and (max-width: 800px){
  .connexion{
    section {
      &.section1{
        position: relative;
        flex-direction: column;
        padding: 15px;
        img{
          filter: brightness(0.8);
          height: 300px;
          width: 100%;
          margin-bottom: 20px;
        }
        .texte{
          margin: 0;
          width: 100%;
          .titre{
            position: absolute;
            top: 100px;
            align-self: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            z-index: 2;
            padding: 0 10px;
            h2{
              color: $white-lc;
            }
            img{
              display: none;
            }
            img.mobile{
              position: unset;
              display: block;
              width: 100px;
              height: auto;
            }
          }
          p{
             margin: 0;
           }
        }
      }
    }
  }
}

@media screen and (max-width: 650px){
  .connexion{
    section{

    }
  }
}




@media screen and (max-width: 900px){

}

@media screen and (max-width: 600px){

}


//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .connexion{
    section{

      &.section1{
        div.imgConnexion{
          width: 40vw;
          height: 100vh;
          overflow: hidden;


          img{
            width:auto;
            height:100%;
            transform: translateX(-600px);

          }
        }

      }
    }

    div.texte{
      form{
        max-width: 90vw;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 800px){
  .connexion{
    section{

      &.section1{
        div.imgConnexion{
          width: 95vw;
          height: auto;
          overflow: hidden;


          img{
            width:100%;
            height:auto;
            transform: none;
          }
        }

      }
    }
  }

}

