.index {
  .section1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px;

    .wrapper{
      max-width: 1200px;
      padding: 0 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .titreAnim{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
          margin-bottom: 30px;
          text-align: center;
        }
      }

      img{
        width: 250px;
        height: auto;
        margin-bottom: 30px;
      }

      .textAnim{
        .cf7_2_post{
          .wpcf7{
            .wpcf7-form{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              p{
                width: 50%;
                input[type = submit]{
                  width: 100%;
                  background-color: transparent;
                  border: 2px solid $black-lc;
                  min-height: 45px;
                  padding: 0 100px;
                  font-family: 'Staatliches', sans-serif;
                  font-size: rem(20);
                  transition: 0.3s ease;

                  &:hover{
                    cursor: pointer;
                    background-color: $black-lc;
                    color: #fff;
                  }
                }
                label{
                  span{
                    input{
                      background-color: transparent;
                      border: 0;
                      border-bottom: 2px solid black;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.index section.section1 .wrapper .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group input {
  background-color: transparent;
  border: 0;
  border-radius: 0!important;
  border-bottom: 2px solid black;
}

.index section.section1 .wrapper .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group select {
  background-color: transparent;
  border-radius: 0!important;
  border: 2px solid black;
}

.index section.section1 .wrapper .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group textarea {
  background-color: transparent;
  border: 2px solid black;
  resize: none;
  border-radius: 0!important;
}

.index section.section1 .wrapper .erf-container .erf-form .erf-submit-button .erf-button button {
  border: 2px solid #2B2B2B;
  min-height: 45px;
  padding: 0 100px;
  font-family: 'Staatliches', sans-serif;
  font-size: 20px;
  background-color: transparent;
}

.index section.section1 .wrapper .erf-container .erf-form .erf-submit-button .erf-button button:hover {
  cursor: pointer;
  background-color: #2B2B2B;
  color: white;
}

.index section.section1 .wrapper .erf-container .erf-form .erf-submit-button {
  display: flex;
  justify-content: center;
}

.index section.section1 .wrapper .erf-container .erf-form .erf-form-html .rendered-form .erf-two-columns .form-group label .erf-required {
  color: #000;
}

@media screen and (max-width: 900px) {
  .index {
    .section1{

      margin-bottom: 10px;
      .wrapper{

        img{
          width: 200px;
        }
        .textAnim{

          h2{
            margin-bottom: 20px
          }
          .cf7_2_post{
            .wpcf7{
              .wpcf7-form{
                p{
                  width: 100%;
                  input[type = submit]{
                    &:hover{
                      color: $bleu-lc;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .index section.section1 {
    background: #C9EAF1;
    padding: 50px 20px;
  }

  .index section.section1 .wrapper {
    width: 100%;
    padding: 0;
  }

  .index section.section1 .wrapper .erf-container {
    max-width: 100%;
  }

  .index section.section1 .wrapper .erf-container .erf-submit-button .erf-button {
    width: 100% !important;
  }

  .index section.section1 .wrapper .erf-form .erf-submit-button .erf-button button {
    padding: 0;
    width: 100%;
  }
}


//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {


  .index{
    .section1{
      .wrapper{
        div.textAnim{
          max-width: 90vw;
        }

        .titreAnim{
          h2{
            max-width: 90vw;
          }
        }

      }

    }


  }
}