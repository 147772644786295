::-webkit-scrollbar {
  display: none;
}

section.video {
  position: relative;
  height: 100vh !important;
  width: 100vw;
  background-color: #231F20;

  .btnPlayPause {
    background-color: #00AFF0;
    opacity: 0;
    height:calc(100% - 60px - 45px - 72px);
    width:calc(100vw - 48px);
    position:absolute;
    top:84px;
    left:24px;
  }

  .videoContainer {
    position: relative;
    padding: 24px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  iframe {
    /* optional */
    width: 100%;
    height: 100%;
    overflow: hidden;

    html {
      overflow-y: hidden;

      body {
        background-color: green;
      }
    }
  }
}

@media screen and (max-width: 900px), screen and (max-height: 880px) {
  section.video {
    height: fit-content !important;

    .btnPlayPause {
      width:calc(100vw - 48px);
      height:calc(56vw - 60px - 45px - 48px);
      max-height: calc(100vh - 60px - 45px - 48px);;
    }

    .videoContainer {
      width: 100vw;
      height: 56vw;
      max-height: 100vh;
    }
  }
}

