.connexion section.section1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.connexion section.section1 img {
  width: 40vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
}

.connexion section.section1 .texte {
  width: 60vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 150px;
}

.connexion section.section1 .texte .titre {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.connexion section.section1 .texte .titre img {
  position: absolute;
  left: -50px;
  height: 130px;
  width: auto;
}

.connexion section.section1 .texte .titre img.mobile {
  display: none;
}

.connexion section.section1 .texte p {
  max-width: 515px;
}

.connexion section.section1 .texte p a {
  color: #8C1B2F;
  font-weight: bold;
}

.connexion section.section1 .texte .erf-container .erf-login-container .erf-form .form-group .form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid black;
  border-radius: 0!important;
}

.connexion section.section1 .texte .erf-container .erf-login-container .erf-form .form-group label .erf-required {
  color: #2B2B2B;
}

.connexion section.section1 .texte .erf-container {
  max-width: 500px;
  padding-right: 50px;
}

.connexion section.section1 .texte .erf-container .erf-login-container .erf-form .erf-submit-button {
  display: flex;
  justify-content: center;
}

.connexion section.section1 .texte .erf-container .erf-login-container .erf-form .erf-submit-button .form-group {
  width: 100%!important;
}

.connexion section.section1 .texte .erf-container .erf-login-container .erf-form .erf-submit-button .form-group button {
  width: 100%;
  padding: 0 100px;
  background-color: transparent;
  border: 2px solid #2B2B2B;
  font-family: 'Staatliches', sans-serif;
  font-size: 20px;
  color: #2B2B2B;
}

.connexion section.section1 .texte .erf-container .erf-login-container .erf-form .erf-submit-button .form-group button:hover {
  background-color: #2B2B2B;
  color: white;
}

.connexion section.section1 .texte .erf-container .erf-login-container div button {
  background-color: transparent;
  border: 2px solid #2B2B2B;
  font-family: 'Staatliches', sans-serif;
  color: #2b2b2b;
}

.connexion section.section1 .texte .erf-container .erf-login-container div button:hover {
  background-color: #2B2B2B;
  color: white;
  cursor: pointer;
}


// ----- mot de passe oublié

.connexion section.section1 .texte .erf-container .erf-password-lost-container .erf-form .form-group input {
  border: 0;
  border-bottom: 2px solid black;
  border-radius: 0!important;
  background-color: transparent;
}
.connexion section.section1 .texte .erf-container .erf-password-lost-container .erf-form .form-group button {
  width: 100%;
  background-color: transparent;
  border: 2px solid #2B2B2B;
  font-family: 'Staatliches', sans-serif;
  font-size: 20px;
  color: #2B2B2B;
}

.connexion section.section1 .texte .erf-container .erf-password-lost-container .erf-form .form-group button:hover{
  background-color: #2B2B2B;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .connexion section.section1 .texte {
    margin-left: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .connexion section.section1 img {
    width: 30vw;
  }

  .connexion section.section1 .texte {
    width: 70vw;
  }

  .connexion section.section1 .texte p {
    margin: 0 20px 0 0;
  }

}

@media screen and (max-width: 900px) {
  .connexion section.section1 {
    background: #C9EAF1;
  }

  .connexion section.section1 .texte .titre img.mobile {
    display: none!important;
  }
}

@media screen and (max-width: 800px) {
  .connexion section.section1 {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px;
  }

  .connexion section.section1 img {
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
  }

  .connexion section.section1 .texte {
    margin: 0;
    width: 100%;
  }

  .connexion section.section1 .texte .titre {
    position: absolute;
    top: 100px;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    padding: 0 10px;
  }

  .connexion section.section1 .texte .titre h2 {
    color: #F2F0F2;
  }

  .connexion section.section1 .texte .titre img {
    display: none;
  }

  .connexion section.section1 .texte .titre img.mobile {
    position: unset;
    display: block!important;
    width: 100px;
    height: auto;
  }

  .connexion section.section1 .texte p {
    margin: 0;
  }

  .connexion section.section1 .texte .erf-container {
    max-width: 100%;
    padding-right: 0;
  }

  .connexion section.section1 .texte .erf-container .erf-login-container div button:hover {
    background-color: #2B2B2B;
    color: #C9EAF1;
  }
}