.fiche-equipe {
  .hero {
    height:380px;
    color:$white-lc;
    padding: 26px;
    padding-top: 40px;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-position: center;

    div {
      h2 {
        color:$white-lc;
        font-weight: 400;
        margin: 0;
      }

      p {
        font-weight: 700;
        margin: 0;
      }
    }

    a {
      width: fit-content;
      position: fixed;
      top: 30px;

      img {
        height:16px;
        width:35px;
      }
    }
  }

  .description {
    padding: 26px;
    padding-top: 32px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      margin-bottom: 36px;
    }

    .bouton {
      padding: 6px 28px;
      border: solid 3px $black-lc;
      transition: 0.5s ease;

      p {
        margin: 0;
        padding-top: 3px;
      }
    }

    .bouton:hover{
      text-decoration: none;
      background-color: $black-lc;
      color: #fff;
    }
  }
}