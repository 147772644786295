footer {
  background: $bleu-lc;

  .modal-parentaires {
    //display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    height: 100vh;
    width: 100vh;
    background-color: rgba(43, 43, 43, 0.8);

    .container-modal {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .close-modal {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 20px;
        right: 20px;
        cursor: pointer;
        filter: invert(1);
        margin: 0;
      }
    }
  }

  .wrapper {
    max-width: 1200px;
    height: 100vh;
    min-height: fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a.bouton {
      transition: 0.3s all;

      &:hover {
        text-decoration: none;
        background-color: #2b2b2b;
        color: $bleu-lc;
      }
    }

    .contenu {
      margin-top: 90px;
      margin-bottom: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .general-lacoulee {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 45%;

        img {
          mix-blend-mode: darken;
        }

        .flex-general {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .adresse {
            margin: 20px 0;

            p {
              margin: 0;
            }
          }

          .contact {
            margin: 20px 0;

            p {
              font-weight: bold;
              margin: 0;
            }
          }

          .medias-sociaux {
            margin-bottom: 20px;
            display: flex;

            a {
              transition: 0.3s all;
              display: inline-block;
              width: auto !important;
              margin: 20px 10px 20px 0;

              img {
                height: 30px;
                width: 30px;
              }
              &:hover {
                cursor: pointer;
                transform: translateY(-5px);
              }
            }
          }
        }

        .bouton {
          padding: 10px 22px;
          border: 3px solid $black-lc;
          width: fit-content;
          cursor: pointer;

          p {
            width: auto;
            display: inline;
          }
        }
      }

      .info-lacoulee {
        width: 42%;

        .horaire {
          p {
            margin: 0;

            span {
              font-weight: bold;
            }
          }
        }

        .infolettre {
          margin: 50px 0 0;

          form input[type=text] {
            width: 100%;
            background-color: transparent;
            border-width: 0;
            border-bottom: 2px solid #000;

          }

          textarea:focus, input:focus {
            outline: none;
          }

          .bouton {
            margin: 20px 0 0;
            padding: 10px 22px;
            border: 3px solid $black-lc;
            display: block;
            text-align: center;

            p {
              width: auto;
              display: inline;
            }
          }
        }
      }
    }

    .credits {
      text-align: center;
      margin: 90px 0;
    }
  }
}

@media screen and (max-height: 880px) {
  footer {
    .wrapper {
      height: auto;
    }
  }
}


@media screen and (max-width: 1500px) {
  footer {
    padding: 0 100px;
  }
}

@media screen and (max-width: 900px) {
  footer {
    padding: 0 20px;

    .mailmunch-embedded iframe {
      left: auto !important;
      top: auto !important;
    }
  }
}

@media screen and (max-width: 900px) {
  footer {
    background-color: $bleu-lc !important;
    height: fit-content;
    padding: 0;

    .wrapper {
      height: fit-content;
      display: block;

      .contenu {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        margin-top: 0;

        .general-lacoulee {
          padding: 66px 20px 50px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          background-color: #ffffff;
          width: 100vw !important;
          align-items: flex-start;

          img {
            max-width: 518px;
            max-height: 200px;
          }

          a.bouton {
            max-width: 500px;
            align-self: center;
          }
          .flex-general {
            justify-content: space-between;
            width: calc(100vw - 60px) !important;
            margin: 20px 0;

            div {
              margin: 0 !important;
              line-height: 140%;

              &:first-child {
                padding-bottom: 15px;
              }
            }
          }

          img {
            width: calc(100vw - 60px);
            height: auto;
          }

          a {
            width: 100% !important;

            &.bouton {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 42px;

              &:hover {
                color: #ffffff;
              }

              p {
                text-align: center;
              }
            }
          }
        }

        .info-lacoulee {
          width: calc(100vw - 40px);
          padding: 50px 0 0;

          .infolettre {
            h4 {
              margin-top: 40px;
              font-size: 36px;
            }

            a {
              &.bouton {
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
              }

              p {
                margin: 0;
              }
            }

            form {
              div {
                height: 50px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-bottom: 20px;

                label {
                  position: absolute;

                  transition: 0.3s all;

                  top: 0;
                  font-size: rem(13);
                }

                .selected {
                  label {
                    top: 0;
                    font-size: rem(13);
                  }
                }
              }
            }
          }

          .horaire {
            h4 {
              font-size: 36px;
            }
          }
        }
      }

      .credits {
        padding: 40px 20px 20px;
        margin: 0;
        font-size: rem(14);
        line-height: 140%;
      }
    }
  }
}

//Styles pour Edge
@supports (-ms-ime-align:auto) {

  @media screen and (max-width: 900px) {

    footer {

      #mc_embed_signup {
        background-color: $bleu-lc;
        position: absolute;
        width: 94%;

        .button {
          max-width: 96%;
        }
      }

      .wrapper {

        .credits {
          padding: 270px 20px 40px 20px;
          background-color: $bleu-lc;
        }

      }

    }

  }
}

//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  footer {
    #mc_embed_signup .button {
      height: 60px;
    }

    .wrapper {
      .credits {
        max-width: 90vw;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 900px) {
  footer {

    .wrapper {
      .contenu {
        .general-lacoulee, .info-lacoulee {
          max-width: 45vw;
        }
      }

    }
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 900px) {
  footer {
    background-color: #fff;

    #mc_embed_signup{
      background-color: #fff;
      .mc-field-group input{
        background-color: #fff;
      }

      .button{
        background-color: #fff;
      }
    }
    .wrapper {

      .credits {
        max-width: 98vw;
      }
      .contenu {

        background-color: #fff;

        .info-lacoulee {
          background-color: #fff;

          .horaire{
            background-color: #fff;
          }

          .infolettre {
            form {
              div {
                height: auto;
              }
            }
          }
        }

      }

    }
  }
}

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{ @media screen {
  footer {
    #mc_embed_signup .button{
      margin: 0;
    }
  }
}}