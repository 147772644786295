.modal-parentaires{
  display: none;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1040;
  height: 100vh;
  width: 100vw;
  background-color: rgba(43,43,43,0.8);
}

.modal-parentaires .container-modal{
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 30px;
}

.modal-parentaires .container-modal .close-modal-partenaires {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  filter: invert(1);
  margin: 0;
}

.modal-parentaires .container-modal .partenaires {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: white;
}

.modal-parentaires .container-modal .partenaires img {
  //display: none;
  width: 15%;
  height: auto;
  margin: 20px;
}

@media screen and (max-width: 700px){
  .modal-parentaires .container-modal{
    padding: 0 10px;
   .partenaires img {
    //display: none;
    width: 25%;
    height: auto;
    margin: 10px;
  }
  }
}

@media screen and (max-width: 400px){
  .modal-parentaires .container-modal{
    .partenaires img {
      //display: none;
      width: 40%;
      height: auto;
      margin: 10px;
    }
  }
}