.membre section.section-membre {
  background: #CEC0D2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.membre section.section-membre .texte {
  margin: 0 100px 0 auto;
  padding-bottom: 20px;
  width: 70vw;
  max-width: 950px;
}

.membre section.section-membre .texte .titre {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.membre section.section-membre .texte .titre img {
  height: 130px;
  width: auto;
  margin-right: 40px;
}

.membre section.section-membre .texte .titre h1 {
  margin: 0;
}

.membre section.section-membre .texte p {
  margin: 20px 0 20px 100px;
}

.membre section.section-membre .texte p:last-of-type {
  margin-bottom: 50px;
}

.membre section.section-membre .texte .bouton {
  margin-left: 100px;
  padding: 10px 22px;
  border: 3px solid #2B2B2B;
}

.membre section.section-membre .texte .bouton p {
  margin: 0;
  width: auto;
  display: inline;
}

.membre section.section-membre .texte .bouton:hover {
  background: #2B2B2B;
  color: #CEC0D2;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

.membre section.section-membre img {
  height: 100vh;
  width: 30vw;
  -o-object-fit: cover;
  object-fit: cover;
}

.membre section.avantages {
  background: $rouge-lc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.membre section.avantages .titre {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.membre section.avantages .titre h2 {
  width: 65%;
  text-align: center;
  margin-bottom: 30px;
}

.membre section.avantages .titre img {
  height: 20px;
  width: auto;
  margin: 0 0 60px;
}

.membre section.avantages .avantages-tarifs {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 70%;
}

.membre section.avantages .avantages-tarifs .les-avantages {
  margin: 0 auto;
  width: 50%;
}

.membre section.avantages .avantages-tarifs .les-avantages ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: url("../images/doodle-liste-orange.svg");
  max-width: 1120px;
  margin: 0 auto;
}

.membre section.avantages .avantages-tarifs .les-avantages ul li {
  width: auto;
  margin: 20px 25px;
}

.membre section.avantages .avantages-tarifs .les-avantages ul li span {
  position: relative;
  top: -5px;
  left: 5px;
}

.membre section.avantages .prix-calltoaction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.membre section.avantages .prix-calltoaction .prix {
  margin: 20px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.membre section.avantages .prix-calltoaction .prix h3 {
  margin: 0;
  text-align: center;
  font-size: 2.5rem;
}

.membre section.avantages .prix-calltoaction .prix .montant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.membre section.avantages .prix-calltoaction .prix .montant p:first-of-type {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 20px;
  font-size: 1.9375rem;
}

.membre section.avantages .prix-calltoaction .bouton {
  padding: 10px 22px;
  border: 3px solid #2B2B2B;
}

.membre section.avantages .prix-calltoaction .bouton p {
  margin: 0;
  width: auto;
  display: inline;
}

.membre section.avantages .prix-calltoaction .bouton:hover {
  background: #2B2B2B;
  color: $rouge-lc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

.membre section.formulaire {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.membre section.formulaire .wrapper {
  max-width: 1200px;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.membre section.formulaire .wrapper .titre {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.membre section.formulaire .wrapper .titre h2 {
  text-align: center;
  margin-bottom: 30px;
}

.membre section.formulaire .wrapper .titre img {
  height: 20px;
  width: auto;
  margin: 0 0 60px;
}


.membre section.formulaire .erf-layout-two-column.erf-container .erf-form .checkbox-group{
  width: 100%;
}

.membre section.formulaire .checkbox label {
  margin-left: 20px;
}

.membre section.formulaire .wrapper .erf-layout-two-column.erf-container .erf-form .form-control {
  background-color: transparent;
  border-bottom: none;
  border-bottom: 2px solid black;
}

.membre section.formulaire .wrapper .form-group label .erf-required {
  color: #2B2B2B;
}

.membre section.formulaire .wrapper .erf-form .erf-submit-button {
  display: flex;
  justify-content: center;
}

.membre section.formulaire .wrapper .erf-form .erf-submit-button .erf-button button {
  border: 2px solid #2B2B2B;
  min-height: 45px;
  padding: 0 100px;
  font-family: 'Staatliches', sans-serif;
  font-size: 20px;
  background-color: transparent;
}

.membre section.formulaire .wrapper .erf-form .erf-submit-button .erf-button button:hover {
  cursor: pointer;
  background-color: #2B2B2B;
  color: white;
}

@media screen and (max-width: 1200px) {
  .membre section.section-membre .texte {
    width: 80vw;
    margin: 0 50px 0 auto;
  }

  .membre section.section-membre img {
    width: 20vw;
  }

  .membre section.avantages {
    padding: 50px 0;
  }
}

@media screen and (max-width: 900px) {
  .membre section.formulaire {
    background: #E6EBBC;
    padding: 50px 20px;
  }

  .membre section.formulaire .wrapper {
    width: 100%;
    padding: 0;
  }

  .membre section.formulaire .wrapper .erf-container {
    max-width: 100%;
  }

  .membre section.formulaire .wrapper .erf-container .erf-submit-button .erf-button {
    width: 100% !important;
  }

  .membre section.formulaire .wrapper .erf-form .erf-submit-button .erf-button button {
    padding: 0;
    width: 100%;
  }

  .membre section.formulaire .wrapper .erf-form .erf-submit-button .erf-button button:hover {
    color: #F2CEA2;
  }

  .membre section.avantages {
    padding: 50px 20px;
  }

  .membre section.avantages .avantages-tarifs {
    flex-direction: column;
    width: 100%;
  }

  .membre section.avantages .avantages-tarifs .les-avantages {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }

  .membre section.avantages .avantages-tarifs .les-avantages ul {
    flex-direction: column;
    width: fit-content;
  }

  .membre section.avantages .prix-calltoaction {
    width: 100%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 650px) {
  .membre section.section-membre {
    position: relative;
    padding: 100px 15px 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .membre section.section-membre .texte {
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    justify-content: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .membre section.section-membre .texte .titre {
    z-index: 2;
    position: absolute;
    top: 210px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .membre section.section-membre .texte .titre h1 {
    -webkit-box-ordinal-group: -1;
    -ms-flex-order: -2;
    order: -2;
    margin-bottom: -40px;
    color: #F2F0F2;
  }

  .membre section.section-membre .texte .titre img {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 0;
  }

  .membre section.section-membre .texte p {
    margin: 20px 0;
  }

  .membre section.section-membre .texte .bouton {
    margin: 0 auto;
  }

  .membre section.section-membre img {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
    width: 100%;
    height: 300px;
  }

  .membre section.avantages .titre h2 {
    width: 90%;
  }

  .membre section.avantages .les-avantages ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .membre section.avantages .les-avantages ul li {
    width: 80%;
  }

}