//FICHIER DE CONFIGURATION POUR LES VARIABLES DE LARGEUR/HAUTEUR

$max-width: 1920px;


/// Dimension pour les media query

$breakpoint-sm: 375px;
$breakpoint-md: 768px;
$breakpoint-lg: 1080px;
$breakpoint-xl: 1920px;


//Dimension pour formulaire
$base-input-height:                             36px ;
$base-input-border-size:                        1px ;
$base-input-border-radius:                      10px ;
$base-select-box-height:                        36px;

