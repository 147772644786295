// STYLE DE BASE DE TOUS LES LIENS ET BOUTON DU SITE

a{
  text-decoration: none;
  &:link{
    color: $color-link;
    text-decoration: none;
  }

  &:visited{
    color: $color-link-visited;
  }

  &:hover{
    color: $color-link-hover;
    text-decoration: underline;
  }

  &:active{
    color: $color-link-active;
  }

  &:focus{
    color: $color-link-focus;
  }
}