.membre{
  section{
    &.section-membre{
      background: $bleu-lc;
      display: flex;
      align-items: center;
      position:relative;

      img.illustrations {
        position:absolute;
        width:17vw;
        height:auto;

        &.gauche {
          left:0;
        }

        &.droite {
          right:0;
          display:none !important;
        }
      }

      .texte{
        margin: 0 100px 0 auto;
        padding-bottom: 50px !important;
        padding-left:8vw;
        width: 70vw;
        max-width: 950px;

        div.titre{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 50px;
          margin-left: 32px;
          margin-top: 50px;

          img{
            height: 80px;
            width: auto;
            margin-right: 40px;
          }

          h1{
            margin: 0;
          }
        }

        p{
          margin: 20px 0 20px 100px;

          &:last-of-type{
            margin-bottom: 50px;
          }
        }

        .bouton{
          margin-left: 100px;
          padding: 10px 22px;
          border: 3px solid $black-lc;

          p{
            margin: 0;
            width: auto;
            display: inline;
          }

          &:hover{
            background: $black-lc;
            color: $bleu-lc;
            transition: all 0.3s;
            text-decoration: none;
          }
        }
      }

      img{
        height: 100vh;
        width: 30vw;
        object-fit: cover;
      }
    }

    &.avantages{
      background: $rouge-lc;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position:relative;

      img.hautGauche {
        position: absolute;
        width: auto;
        top: 0;
        left: 0;
        transform: rotate3d(0, 0, 1, 180deg);
      }

      img.hautDroite {
        position: absolute;
        //height: 40%;
        width: auto;
        top: 0;
        right:0;
        transform: rotate3d(1, 0, 0, 180deg);
      }

      img.basGauche {
        position: absolute;
        //height: 40%;
        width: auto;
        bottom: 0;
        left:0;
        transform: rotate3d(0, 1, 0, 180deg);
      }

      img.basDroite {
        position: absolute;
        //height: 40%;
        width: auto;
        bottom: 0;
        right: 0;
      }

      .titre{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
          width: 65%;
          text-align: center;
          margin-bottom: 30px;
        }

        img{
          height: 20px;
          width: auto;
          margin: 0 0 60px;
        }
      }

      .les-avantages{
       margin: 0 auto;
        ul{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-wrap: wrap;
          list-style: url("../images/doodle-liste-orange.svg");
          max-width: 1120px;
          margin: 0 auto;
          li{
            width: 28%;
            margin: 20px 25px;
            span{
              position: relative;
              top: -5px;
              left: 5px;
            }
          }
        }
      }
      .prix{
        margin: 20px 0 50px;
        h3{
          margin-bottom: 0;
          text-align: center;
          font-size: rem(40);
        }
        .montant{
          display: flex;
          justify-content: space-between;
          align-items: center;
          p:first-of-type{
            margin-right: 20px;
            font-size: rem(31);
          }
        }
      }
      .bouton{
        padding: 10px 22px;
        border: 3px solid $black-lc;
        p{
          margin: 0;
          width: auto;
          display: inline;
        }
        &:hover{
          background: $black-lc;
          color: $rouge-lc;
          transition: all 0.3s;
          text-decoration: none;
        }
      }

    }

    &.formulaire{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .titre{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2{
          text-align: center;
          margin-bottom: 30px;
        }
        img{
          height: 20px;
          width: auto;
          margin: 0 0 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .membre{
    section{
      &.section-membre{
        .texte{
          width: 80vw;
          margin: 0 50px 0 auto;

        }
        div.imgMembres{
          align-self: stretch;

          img{
            height: 100%;
            width: 20vw;
          }
        }

      }
      &.avantages{
        padding: 50px 0 !important;
      }
    }
  }

}

@media screen and (max-width: 1200px) and (min-width: 650px){
  .membre{
    section{
      &.section-membre{
        .texte{

          div.titre h1{
            font-size: 70px;
          }
        }

      }

    }
  }
}

@media screen and (max-width:1000px){
  .membre section.devenir-membre {

  }
}

@media screen and (max-width: 650px){
  .membre{
    section{


      &.section-membre{
        position: relative;
        padding: 100px 15px 150px;
        flex-direction: column;

        div.imgMembres{
          order: -1;
          align-self: auto;

          img{
            order: -1;
            filter: brightness(0.7);
            width: 100%;
            height: 300px;
          }
        }


        img.illustrations {
          width:30vw;
          height:auto;
          bottom:0;

          &.gauche {
            left:0;
          }

          &.droite {
            right:0;
            transform:scaleX(-1);
            display:block !important;
          }
        }

        .texte{
          width: 100%;
          margin: 0;
          justify-content: normal;
          display: flex;
          flex-direction: column;
          div.titre{
            z-index: 2;
            position: absolute;
            top: 210px;
            flex-direction: column;
            justify-content: center;
            margin: 0 20px;
            left: 0;
            right: 0;
            text-align: center;
            h1{
              order: -2;
              margin-bottom: -40px;
              color: $white-lc;
            }
            img{
             transform: rotate(90deg);
             margin: 20px 0 0 0;
            }
          }
          p{
            margin: 25px 0;
          }
          .bouton{
            margin: 0 auto;
          }
        }
      }
      // fin section membre

      // début section avantages
      &.avantages{
        .titre{
          h2{
            width: 90%;
          }
        }
        .les-avantages{
          ul{
            flex-direction: column;
            li{
              width: 80%;
            }
          }
        }
      }
      // fin section avantages

      // début section formulaire
      &.formulaire{
        background: $orange-lc;
        padding: 50px 0;
      }
    }
  }

  .membre section.section-membre {
    padding-bottom: 180px !important;
  }
}

@media screen and (max-width: 900px), (max-height:880px) {
  section.avantages {
    padding-top: 150px !important;
    padding-bottom: 150px !important;

    img.hautGauche, img.hautDroite, img.basGauche, img.basDroite {
      height:15%;
    }
  }
}





//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {


  .membre {
    section {

      &.section-membre{

        div.imgMembres{

          width: 20vw;
          overflow: hidden;

          img{
            height: 100%;
            width: auto;
            transform: translateX(-760px);

          }
        }

      }

      &.formulaire{
        form{
          max-width: 90vw;
        }
      }

    }
  }

}



@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 650px){
  .membre {
    section {

      &.section-membre{

        padding: 120px 15px 15px;

        div.imgMembres{

          width: 100vw;
          overflow: hidden;
          height: 230px;

          img{
            height: auto;
            width: 100%;
            transform: none;

          }
        }

        .texte{

          .bouton{
            margin-bottom: 20px;
          }

          div.titre{
            top:0;

            margin-top: 20px;
            margin-left: 0;

            h1{
              color: $black-lc;
            }

          }

          div.textAnim:first-child{
            margin-top: 35px;
          }

        }

      }

      &.avantages{

        .titre{
          h2{
            max-width: 90vw;
          }
        }

        .les-avantages{
          max-width: 95vw;
        }
      }

    }
  }

}