.single-cours {
  #fleche-retour2.fleche{
  width: fit-content;
    height: 30px;
  img {
    height:30px;
    width:auto;
    filter: drop-shadow(-1px 1px 3px rgba(0, 0, 0, 0.5));
  }
    .fleche-menu{
      display: none;
    }
    &:hover{
      display: flex;
      text-decoration: none;
      img{
        filter: brightness(0.5);
      }
      .fleche-menu{
        margin-left: 10px;
        display: block;
        position: relative;
        top: -10px;
        p{
          background: $black-lc;
          padding: 10px;
          margin: 0;
          font-family: "Staatliches", sans-serif;
          font-size: rem(20);
          color: $bleu-lc;
          &:hover {
            color: $bleu-lc-light;
          }
        }
      }
    }
}
  section {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;



    div.texte {
      max-width: 800px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 20vw;
    }

    .bouton{
      margin-top: 20px;
      padding: 10px 22px;
      border: 3px solid $black-lc;
      cursor: pointer;
      display: inline;
      align-self: flex-start;
      transition: background 0.5s ease;

      p{
        margin: 0;
        width: auto;
        display: inline;
      }

      &:hover{
        background: $black-lc;
        text-decoration: none;
      }
    }

    &.survol-cours {
      justify-content: space-between;
      background-color: $bleu-lc;

      .mobile{
        display: none;
      }

      .thumbnail {
        position:relative;
        width: 40vw;
        height: 100vh;
        object-fit: cover;
        margin-left: 80px;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center center;

        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          color:$white-lc;
          text-align: center;
        }

        img {
          width:100px;
          align-self:center;
          margin-bottom: 0;
        }
      }

      div.texte{
        margin-left: auto;
        padding-left: 100px;
        width: 60vw;
        h2{margin: 20px 0;}
      }

      .bouton:hover{
        color: $bleu-lc;
      }

      img {
         width: 100px;
         height: auto;
         margin-bottom: 0;
       }

      div.cours-informations {
        display: flex;
        flex-wrap: wrap;

        div{
          width: 50%;
          margin: 10px 0;
          padding: 0 10px;

          h3, p {
            margin: 5px 0;
            padding: 0
          }

          div.repeteur-materiel{
            padding: 0;
            margin:0;
            width: 100%;
            div{
              padding:0;
              margin:0;
              width:100%;
              display: flex;
              span{font-weight: $fw-bold;}
              p{margin:0;}
            }
          }
        }

      }
    }

    &.description-cours{
      position: relative;
      background-color: $orange-lc;
      height: 100vh;

      img{width: 50px; height: auto;}

      .bouton:hover{
        color: $orange-lc;
      }

      span{font-weight: $fw-bold;}


      img#illustration-vague{
        position: absolute;
        width: auto;
        height: 60vh;
        top: 40vh;
        margin-top: -20vh;
        transform: rotate3d(0, 1, 0, 180deg);
        right: 0;
        //right: 0;
        //height:auto;
        //width:20vw;
        //bottom:0;
        //top:auto;
      }

      img#illustration-vague-mobile{
        display: none;
      }

      div.texte{
        a{
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px){
  .single-cours{
    section{
      div.texte{
        width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 1500px){
  .single-cours{
    section{
      &.survol-cours{
        .thumbnail{
          width: 20vw;
        }
        div.texte {
          padding-left: 0;
          width: 80vw;
          margin-left: 20vw;
        }
      }

    }
  }
}

@media screen and (max-width: 1380px){
  .single-cours{
    section{


      div.texte{
        max-width: 700px;
        margin-left: 16vw;
      }

      &.survol-cours{
        padding-left: 100px;
      }

    }
  }
}

@media screen and (max-width: 1130px){
  .single-cours{
    section{

      &.description-cours{
        img#illustration-vague{
          display: none;
        }
      }
    }
  }
}


@media screen and (max-width: 900px){
  .single-cours{
    section{

      div.texte{
        max-width: none;
        width: 100%;
        .bouton{align-self: center;}
      }

      &.survol-cours{
        position: relative;
        padding: 50px 20px;
        flex-direction: column;
        align-items: center;
        .mobile{
          margin-top:0;
          display: inline;
          width:100%;
          order: -2;
        }
        .thumbnail{
          order: -1;
          width: 100%;
          height: 300px;
          margin: 0;

          img{
            width: 80px;
          }

          h2{
            margin-bottom: 15px;
            padding: 0 15px;
          }
        }
        div.texte {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0 0;

          h3.desktop{display: none;}

          h2,img{
            position: absolute;
            top: 220px;
            color: $white-lc;
            text-align: center;
            margin: 0 auto;
          }
          img{
            top: 290px;

          }
          h2{
            width:80%;
            margin-bottom: 10px;
            padding: 0 15px;
            line-height: 40px;
          }
        }

        div.cours-informations {
          width:100%;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          div{
            padding:0;
            width: 100%;
          }
        }
      }

      &.description-cours {
        padding: 50px 20px 35vw;
        min-height: 100vh;
        height: auto;

        div.texte {
          margin: 0;

          img#doodle-titre {
            align-self: center;
          }

          p#titre-cours {
            width: 100%;
            text-align: center;
          }
        }

        img#illustration-vague{
          display: none;
        }
        img#illustration-vague-mobile{
          //transform: none;
          position: absolute;
          display: block;
          width: 80vw;
          height:auto;
          bottom:0;
          //right:0;

          //position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%)
        }
      }
    }
  }
}

@media screen and (max-width: 900px){
  .single-cours{
    #fleche-retour2.fleche{
      display: none;
      img {
        height:16px;
        width:auto;
      }
    }
  }
}

@media screen and (max-width: 800px){
 section{
   &.description-cours{
     min-height: auto !important;
     height: auto;
   }
 }
}

@media screen and (max-width: 600px){
  .single-cours{
    section.description-cours{
      img#illustration-vague-mobile{
        width: 90vw;
      }
    }
  }
}

@media screen and (max-height: 880px){
  section{
    height: auto !important;

    &.description-cours{
      padding: 50px 0;
    }
  }
}


