#open-modal {
  position: fixed;
  z-index: 100;
  color: #fff;
  top: 60px;
  right: 10px;

  font-family: Staatliches, sans-serif;
  font-size: 36px;
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
  transform: rotate(90deg);
  display: inline-block;

  transition: 0.5s ease;

  &:hover {
    color: $rouge-rose-lc-light;
    cursor: pointer;
    transform: translateX(-10px);
  }
}

.modal {

  #close-modal {
    transition: 0.5s ease;
    right: 15px;
    top: 35px;

    &:hover {
      color: #fff;
      cursor: pointer;
      transform: translateX(-30px);
    }
  }
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
  //display: block;
  //display: none;
  position: fixed;
  //top:0;
  //left:0;
  z-index: 1050;
  min-height: 100vh;
  min-width: 100vw;
  overflow-y: auto;
  background-color: $black-lc-dark;

  .close-modal {
    transform: rotate(90deg);
    font-family: "Staatliches", sans-serif;
    font-size: 36px;
    color: $rouge-rose-lc;
    position: absolute;
    top: 60px;
    right: 0;
    &:hover {
      cursor: pointer
    }
  }

  .container-modal {
    padding: 20px 50px;
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: space-around;
    align-items: center;

    a {
      width: fit-content;
      height: fit-content;
      order: 2;

      img {
        height: auto;
        width: auto;
      }
    }

    .horaire {
      color: $white-lc;
      margin-top: 50px;
      h4 {
        color: $white-lc;
      }
    }

    nav {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 20px;
        ul {
          li {
            a {
              color: $bleu-lc;
            }
            a:link {
            }
            a:hover {
              color: $bleu-lc-light;
            }
          }
        }
      }
      ul {
        li {
          list-style: none;
          padding: 0;
          margin: 20px 0;
          a {
            display: inline-block;
            font-family: "Staatliches", sans-serif;
            color: $orange-lc;
            font-size: rem(30);
            transition: all 0.5s ease;
          }

          a:hover {
            text-decoration: none !important;
            transform: translateX(8px);
            color: $orange-lc-light;
          }
          .sub-menu {
            padding: 0;
            display: inline;
            li {
              display: inline;
              a {
                font-size: rem(20);
                color: $orange-lc-light;
              }
              &:before {
                content: '/ ';
                color: $bleu-lc;
              }
              &:first-child:before {
                content: '-';
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.haut-logo {
  height: fit-content;
  top: 45px;
  left: 13px;
  position: fixed;
  z-index: 10;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;

  .menu-user ul li a:hover{
    text-decoration: none;
  }

  a {
    position: relative;
    padding: 10px 0;
    display: flex;
    align-items: center;

    transition: all 0.3s;
    &:hover {
      img {
        transition: all 0.3s;
        filter: brightness(0.5) opacity(0.9);

      }

    }
  }

  img {
    width: rem(30);
    filter: drop-shadow(-1px 1px 3px rgba(0, 0, 0, 0.5));

  }


  .icone-user {
    position: relative;
    cursor: pointer;
    &:hover {
      .menu-user {
        display: block;
      }
      img {
        filter: brightness(0.5) opacity(0.9);
        transition: all 0.3s;

      }
    }
    &.icone-accueil{
      .menu-user{
        top: -10px;
        p{
          background: $black-lc;
          padding: 10px;
          font-family: "Staatliches", sans-serif;
          font-size: rem(20);
          color: $bleu-lc;
          &:hover {
            color: $bleu-lc-light;
          }
        }
      }

    }
  }

  .icone2 {
    display: none;
    padding: 0;
      .menu-user{
        top: -20px;
        p{
          background: $black-lc;
          padding: 10px;
          font-family: "Staatliches", sans-serif;
          font-size: rem(20);
          color: $bleu-lc;
          &:hover {
            color: $bleu-lc-light;
          }
        }
      }
  }

  .menu-user {
    display: none;
    position: absolute;
    top: 0;
    margin: 0 0 0 30px;
    padding: 0 0 0 10px;
    width: max-content;
    ul {
      background: $black-lc;
      list-style: none;
      padding: 0 10px;
      margin: 0;
      li {
        a {
          font-family: "Staatliches", sans-serif;
          font-size: rem(20);
          color: $orange-lc;
          &:hover {
            color: $orange-lc-light;
          }
        }
        &:last-child {
          a {
            color: $bleu-lc;
            &:hover {
              color: $bleu-lc-light;
            }
          }
        }
      }

    }

  }
}

@media screen and (max-width: 1500px) {
  .modal {
    .container-modal {
      a img {
        width: 40vw;
      }
      nav ul li {
        .sub-menu {
          display: block;
          padding-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  #open-modal {
    font-size: rem(30);
  }
  .modal {
    #close-modal {
      font-size: rem(30);
    }
    .container-modal {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      a {
        img {
          width: 60%;
        }
      }
      nav ul {
        padding: 0;
      }

      .autre {
        order: -1;
        .horaire {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 400px), screen and (max-height: 800px) {
  .modal {
    .container-modal {
      justify-content: flex-start;
      nav ul {
        min-width: 40vw;
        li {
          margin: 10px 0;
          a {
            font-size: rem(23);
          }
          .sub-menu {
            padding-top: 2px;
            li {
              a {
                font-size: rem(15);
              }
            }
          }
        }
      }
    }
  }
  #open-modal {
    top: 15px;
  }
}

@media screen and (max-width: 400px) {
  .haut-logo {
    top: 0;
    left: 0;
    display: none;
  }
}

@media screen and (max-width: 900px) and (max-height: 640px) {
  .modal {
    .container-modal {
      .autre {
        display: none;
      }
    }
  }
}


//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .haut-logo{
    img[src$=".svg"] {width: 100%;}

  }

}