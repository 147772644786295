header.header-section{
  height: 100vh;
  background-size:cover;
  background-position: 50% 50%;
  background-color: #fff;
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5));
  background-blend-mode: multiply, normal;
  position: relative;

  div{
    position:absolute;
    bottom: 20%;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 500px;
    align-items:center;
    img{
      width: auto;
    }
    h1{
      margin-left: 30px;
      color: $white-lc;
      width: 550px;
      line-height: 130%;
    }
  }
}

@media screen and (max-width: 1500px) {
  header.header-section{
    div{
      margin-left: 100px;
      padding-right: 300px;
    }
  }

}

@media screen and (max-width: 900px) {
  header.header-section{
    div{
      //height: 100%;
      width: 100%;
      position:absolute;
      bottom: 40%;
      right: 0;
      padding: 0;
      line-height: normal;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;

      img{
        display: block;
        transform-origin: bottom;
        transform: rotate(90deg) translateY(50%);
        margin-top: auto;
        height: 30%;
        width: auto;
        margin-bottom: 20px;
      }
      h1{
        width: 100%;
        margin-left: 0;
        text-align: center;
        line-height: inherit;
      }
    }
  }
}