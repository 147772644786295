.atelier {
  section {
    &.visite {
      background-color: #231F20;
      position:relative;

      min-height: 40vh;

      div#gameContainer {
        width: 100vw;
        height: 100vh;
      }

      p.erreurVisite {
        display: none;

        color: white;
        text-align: center;

        margin: auto;

        position: absolute;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        height: fit-content;
        width: fit-content;
        max-width: calc(100vw - 120px);
      }

      div.controles {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: 42;

        width: fit-content;
        height: fit-content;

        margin-left: auto;
        margin-right: auto;
        padding: 32px 52px;

        background-color: #2b2b2b;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #f4f4f4;
        }

        img {
          width: 106px;
          height: 68px;

          margin-left: 32px;
          margin-right: 32px;
        }
      }
    }
  }

  div.controlesContainer {
    display: none;
  }
}

@media screen and (max-height: 880px), screen and (max-width: 900px) {
  .atelier {
    section {
      &.visite {
        height: 40vh;

        p.erreurVisite {
          display: block;
        }
      }
    }
  }
}

//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

}

//Styles pour Edge
@supports (-ms-ime-align:auto) {

}

//Styles pour Firefox
@-moz-document url-prefix() {

}