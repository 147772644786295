// LES STYLES DE BASE DES BALISES <h>


h1{
  font-size: rem(100);
  font-weight: 400;
  font-family: 'Staatliches', sans-serif;
  color: $black-lc;
  letter-spacing: 0.18em;
  line-height: 95%;
  text-transform: uppercase;
}

h2{
  font-size: rem(72);
  font-weight: 400;
  font-family: 'Staatliches', sans-serif;
  color: $black-lc;
  letter-spacing: 0.18em;
  line-height: 95%;
  text-transform: uppercase;
}

h3{
  font-size: rem(22);
  padding: 0 0 10px;
  font-family: 'Staatliches', sans-serif;
  color: $black-lc;
  letter-spacing: 0.18em;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: normal;
}

h4{
  font-size: rem(18);
  font-family: 'Staatliches', sans-serif;
  font-weight: normal;
  color: $black-lc;
  letter-spacing: 0.15em;
  line-height: 95%;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.bouton{
  font-size: rem(18);
  font-family: 'Staatliches', sans-serif;
  color: $black-lc;
  letter-spacing: 0.18em;
  line-height: 30px;
  text-transform: uppercase;
}


@media screen and (max-width: 1500px){
  h1{
    font-size: rem(80);
  }

  h2{
    font-size: rem(54);
  }

  .bouton{
    font-size: rem(16);
  }
}

@media screen and (max-width: 900px){
  h1{
    font-size: rem(48);
  }

  h2{
    font-size: rem(36);
  }

  h3{
    font-size: rem(22);
  }

  .bouton{
    font-size: rem(14);
  }
}
