.atelier {

  header {
    background-image: linear-gradient(0deg, rgba(140, 130, 130, 0.52), rgba(140, 130, 130, 0.52)), url("../images/atelier.png");
  }

  section {
    height: 100vh;
    position: relative;

    .titreFlex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    & > img.illustration {
      position: absolute;
      height: 82vh;
      width: auto;

      &.gauche {
        left: 0;
      }

      &.droite {
        right: 0;
        transform: scaleX(-1);
      }

      &.imgBas {
        bottom: 0;
        width: 90vw;
        height: auto;
        left: 50vw;
        margin-left: -45vw;
      }
    }

    .haut {
      max-width: 900px;
      margin: auto;

      h2 {
        color: $black-lc;
        background: url("../images/doodle-titres.png") no-repeat 0 100%;
        max-width: 600px;
      }
    }

    .bas {
      background: $bleu-lc;

      .wrapper {
        max-width: 900px;
        margin: 70px auto 50px;
        display: flex;
        flex-direction: column;

        h3 {
          margin: 0;
        }

        .points {
          display: flex;
          font-size: rem(15);

          .flex {
            width: 50%;

            div {
              display: flex;
              align-items: flex-start;
              margin: 20px 0;

              img {
                width: auto;
                //height: 40px;
                margin-right: 20px;
              }

              p {
                margin: 0;
              }
            }

            &.flex-droit {
              div {
                ul {
                  margin: 0;
                  list-style: none;
                  padding-left: 25px;

                  li:before {
                    content: '- ';
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  section.galerie {
    background: $black-lc;

    .plan {
      height: 15vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .bouton {
        margin: auto;
        padding: 10px 22px;
        border: 3px solid $white-lc;
        cursor: pointer;
        display: inline;
        align-self: flex-start;
        color: $white-lc;

        p {
          margin: 0;
          width: auto;
          display: inline;
        }

        &:hover {
          transition: all 0.3s;
          background: $white-lc;
          color: $black-lc;
          text-decoration: none;
        }
      }
    }

    .images {
      position: relative;
      height: 85vh;
      padding: 10px;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 33.3% 33.3% 33.3%;
      grid-template-columns: 33.3% 33.3% 33.3%;
      -ms-grid-rows: 50% 50%;
      grid-template-rows: 50% 50%;
      grid-template-areas: "div1 div2 div3" "div4 div5 div6";

      img#doodle1 {
        position: absolute;
        height: 60%;
        width: auto;
        -ms-grid-column: 2;
        grid-column: 2;
        left: -15px;
        top: 20%;
      }

      img#doodle2 {
        position: absolute;
        width: 50%;
        height: auto;
        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 2;
        grid-row: 2;
        top: -15px;
      }

      div {
        margin: 10px !important;
      }

      div#image1 {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        background-image: url("../images/galerie-img1.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div1;
      }

      div#image2 {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        background-image: url("../images/galerie-img2.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div2;
      }

      div#image3 {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        background-image: url("../images/galerie-img3.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div3;
      }

      div#image4 {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        background-image: url("../images/galerie-img4.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div4;
      }

      div#image5 {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        background-image: url("../images/galerie-img5.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div5;
      }

      div#image6 {
        -ms-grid-row: 2;
        -ms-grid-column: 3;
        background-image: url("../images/galerie-img6.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div6;
      }
    }

  }

  .techniques {
    display: flex;
    background: $bleu-lc;
    position: relative;
    min-height: 900px;

    .bouton {
      background: $bleu-lc;
    }

    img#illustrations-fonderie, img#illustrations-soudure {
      position: absolute;
      height: 70%;
      width: auto;
      right: 0;
      top: 15%;
    }

    img#illustrations-soudure-mobile, img#illustrations-fonderie-mobile {
      display: none;
    }

    .mobile {
      display: none;
    }

    img.thumbnail {
      width: 40vw;
      height: 100vh;
      object-fit: cover;
      margin-right: 80px;
      min-height: 900px;
    }

    .texte {
      width: 60vw;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .general {
        max-width: 800px;

        h2 {
          margin: 20px 0 10px 0;
        }

        img {
          width: 100px;
          height: auto;
          margin-bottom: 10px;
        }
      }

      .liste-prix {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;

        div.Non-membres {
          margin-right: 25px;
        }

        div.un-prix.liste {
          display: flex;

          p.ou {
            margin-right: 20px;
          }
        }

        h4 {
          font-size: rem(18);
        }

        .prix {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-right: 20px;

          p {
            font-family: "Space Mono", monospace;
            font-size: rem(20);
            margin: 0;
          }

          .par {
            font-size: rem(14);
            margin: 10px 0 0;
          }
        }

        .materiaux {
          width: 50%;
          margin-bottom: 50px;

          .un-materiel {
            display: flex;
            margin: 10px 0;
          }
        }
      }

      .temps {
        width: 48%;

        h3 {
          margin-bottom: 0;
        }

        h4 {
          margin: 30px 0;
        }

        .prix-membre, .prix-non-membre {
          .liste {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            .prix {
              margin: 0;
            }
          }
        }
      }

      .coulee {
        width: 50%;

        h3 {
          margin-top: 0;
        }

        .liste {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .un-prix {
            p {
              font-family: "Space Mono", monospace;
              font-size: rem(20);
              margin: 0;
            }

            &:last-child {
              margin-right: 50px;
            }
          }

        }
      }

      .bouton {
        padding: 10px 22px;
        border: 3px solid $black-lc;
        cursor: pointer;
        display: inline;
        align-self: flex-start;

        p {
          margin: 0;
          width: auto;
          display: inline;
        }

        &:hover {
          background: $black-lc;
          color: $bleu-lc;
          text-decoration: none;
        }
      }
    }
  }

  .techniques.soudure {
    background: $orange-lc;

    .bouton {
      background-color: $orange-lc;
    }

    .texte {

      .liste-prix {
        align-items: flex-start;

        .membres {
          width: 52%;
          padding: 0 0 0 4%;
          border-left: 3px solid transparent;
          border-image: url("../images/ligne-droite-rouge.svg") 30 stretch;

          .un-prix {
            display: flex;
            align-items: center;

            .prix {
              font-family: "Space Mono", monospace;
              font-size: rem(20);
              margin: 0 20px 0 0;
            }

            &.liste {
              margin: 25px 0;

              .prix {
                margin: 0;
              }

              .ou {
                margin: 0 20px;
              }
            }
          }

          .note {
            font-size: rem(14);
          }
        }

        .non-membres {
          width: 48%;

          .un-prix.liste {
            margin: 25px 0;
            display: flex;
            align-items: center;

            .prix {
              margin: 0;

              p {
                margin-bottom: 0;
              }
            }

            .ou {
              margin: 0 20px;
            }
          }
        }

        a.bouton {
          margin-top: 20px;
        }

        a.bouton:hover {
          color: $orange-lc;
        }
      }
    }
  }

  .equipement {
    background: $rouge-lc;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    img#illustrations-outils-gauchehaut,
    img#illustrations-outils-gauchebas,
    img#illustrations-outils-droitehaut,
    img#illustrations-outils-droitebas {
      position: absolute;
      width: auto;
      height: 25%;
    }

    img#illustrations-outils-gauchehaut {
      transform: scale(-1);
      top: 0;
      left: 0;
    }

    img#illustrations-outils-gauchebas {
      transform: scaleX(-1);
      bottom: -100px;
      left: 0;
    }

    img#illustrations-outils-droitehaut {
      transform: scaleY(-1);
      top: 0;
      right: 0;
    }

    img#illustrations-outils-droitebas {
      bottom: -100px;
      right: 0;
    }

    h2 {
      margin: 20px 10px;
    }

    img {
      width: 300px;
      height: auto;
      margin-bottom: 100px;
    }

    ul {
      list-style: url("../images/crochet-liste.svg");
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
      padding: 0 20px 0 50px;

      li {
        margin: 10px 20px;
        padding-right: 20px;
        width: 28%;
      }
    }
  }

  .regles {
    background: $orange-lc;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin: 80px 0 20px;
    }

    img {
      width: 250px;
      height: auto;
      margin: 15px 0;
    }

    .nav-fleches{
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .fleches-nav-haut{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 48px;
          width: auto;
          cursor: pointer;
          filter: brightness(0.2);
          &.suiR{
            transform: rotate(180deg);
          }
          &:hover{
            filter: brightness(0.5);
            transition: all 0.4s;
          }
        }
      }
    }

    .nav-regles {
      display: flex;

      a {
        padding: 8px 18px;
        border: 3px solid $black-lc;
        border-left: none;
        cursor: pointer;

        &:first-of-type {
          border-left: 3px solid $black-lc;
        }

        &:hover {
          background: $black-lc;
          color: $orange-lc;
          text-decoration: none;
          transition: all 0.3s;
        }

        &.actif {
          background: $black-lc;
          color: $orange-lc;
        }
      }
    }

    .les-regles {
      display: none;
      justify-content: space-between;
      max-width: 1120px;
      margin: 0 auto;

      .une-regle {
        width: 45%;
        display: flex;
        flex-direction: column;

        .titre {
          display: flex;
          align-items: center;

          img {
            height: 40px;
            width: auto;
            margin: auto 0;
          }

          h3 {
            padding-bottom: 0;
            margin-left: 20px;
          }
        }

      }
    }
  }
}

@media screen and (min-height: 1000px) {
  .atelier {
    section {
      .titreFlex{
        padding-bottom: 3vh;
      }
    }
  }
}

@media screen and (min-height: 1100px) {
  .atelier {
    section {
      .titreFlex{
        padding-bottom: 5vh;
      }
    }
  }
}


@media screen and (max-width: 1750px) {
  .atelier {
    section {
      &.techniques {
        img#illustrations-fonderie, img#illustrations-soudure {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1090px) {
  .atelier {
    section.techniques {

      div.texte {

        h3 {
          font-size: rem(18);
        }

        h4 {
          font-size: rem(16)
        }

        .general {
          h2 {
            font-size: rem(45);
          }
        }

        div.texte {

          .liste-prix {

            .non-membres, .membres {
              width: 40%;
            }

            .membre {
              .note {
                line-height: 20px;
                margin-bottom: 0;
              }
            }
          }

          .general {
            :last-child {
              font-size: rem(15);
              margin-top: 20px;
            }

            h2 {
              font-size: rem(45);
            }

            div {
              max-width: 675px;
            }
          }

        }
      }

    }

  }
}

@media screen and (min-height: 980px) {
  .atelier {
    section.regles {
      h2 {
        margin-top: 100px;
      }
    }

  }
}

@media screen and (min-height: 1000px) {
  .atelier {
    section.regles {
      h2 {
        margin-top: 120px;

      }
    }

  }
}

@media screen and (max-width: 1500px) {
  .atelier {
    section {
      height: auto;

      &.info {
        .haut {
          padding: 0 70px;
        }

        .bas {
          padding: 0 100px;
        }

      }

      &.techniques {
        img#illustrations-fonderie, img#illustrations-soudure {
          display: block;
        }
        img.thumbnail {
          margin-right: 40px;
          width: 20vw;
        }

        .texte {
          padding-right: 10px;
          width: 80vw;

          .liste-prix {
            .prix {
              text-align: center;

              .par {
                width: 80px;
              }
            }
          }
        }
      }

      &.equipement {
        img#illustrations-outils-gauchehaut,
        img#illustrations-outils-gauchebas,
        img#illustrations-outils-droitehaut,
        img#illustrations-outils-droitebas {
          position: absolute;
          width: auto;
          height: 23%;
        }

        div.titreFlex {
          margin-top: 80px;
        }

        ul {
          width: 90%;
          padding: 0 20px 0 100px;
          justify-content: center;
          margin-bottom: 80px;

          li {
            width: 40%;
          }
        }
      }

      &.regles {
        h2 {
          text-align: center;
          //margin-top: 80px;
          width: 65%;
        }

        img {
          margin: 20px 0;
        }

        .les-regles {
          margin: 0 100px 70px 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .atelier {
    section {
      &.techniques {
        img#illustrations-fonderie, img#illustrations-soudure {
          display: none;
        }

        .texte {
          .general {
            .textAnim {
              margin-top: 20px;
              padding-right: 15px;
            }
          }

          a.bouton {
            margin-top: 30px;
          }

          .liste-prix {
            padding-bottom: 30px;

            div.materiaux {
              margin-bottom: 0;
            }
          }
        }
      }

      &.regles {
        div.titreAnim img {
          max-width: 150px;
          margin: 0;
        }

        div.nav-regles {

          a {
            padding: 7px 10px;
          }
        }

        .les-regles {
          .une-regle {
            p {
              font-size: rem(15);
              line-height: 22px;
            }

            .titre {
              h3 {
                margin: 5px 0 0 20px;
              }
            }
          }
        }

        h2 {
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .atelier {
    section {
      &.info {
        .haut {
          padding: 20px 20px 50px;
          display: flex;
          align-items: center;
          flex-direction: column;

          h2 {
            background-size: contain;
            background-position-x: center;
            text-align: center;
            padding: 20px 0 0;
            width: 65%;
          }
        }

        .bas {
          padding: 0 20px;
          padding-bottom: 30px !important;

          .wrapper {
            .points {
              justify-content: space-between;

              .flex {
                width: 48%;

                &.flex-gauche {
                  margin-right: 5px;
                }

                &.flex-droit {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      &.techniques {
        position: relative;
        flex-direction: column;
        padding: 20px;
        min-height: 0;

        div.texte {
          h3 {
            padding-top: 20px;
          }

          .liste-prix {
            & > div {
              padding-top: 20px;
            }

          }

        }

        img#illustrations-fonderie-mobile, img#illustrations-soudure-mobile {
          display: block;
          position: absolute;
          width: 65%;
          height: auto;
          bottom: 0;
          left: 15%;
        }

        .mobile {
          display: block;
        }

        img.thumbnail {
          width: 100%;
          height: 300px;
          filter: brightness(0.6);
          min-height: 0;
        }

        div.texte {

          h2 {
            font-size: rem(36) !important;
          }

          div.texte {
            .general {
              div {
                max-width: none;
              }
            }

            .liste-prix {

              .non-membres, .membres {
                width: 50%;
              }
            }

          }
        }

        .texte {
          padding-bottom: 20px;
          width: 100%;

          .general {
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
              display: none;
            }

            h2, img {
              position: absolute;
              top: -250px;
              color: $white-lc;
              width: 100vw;
              text-align: center;
              margin: 0 auto;
              z-index: 2;
              left: -20px;
              margin-left: -50%;
            }

            img {
              top: -200px;
              width: 90px;
              left: 50vw;
              margin-left: -75px;
            }
          }
        }
      }

      &.equipement {
        padding: 150px 0;

        img#illustrations-outils-gauchehaut,
        img#illustrations-outils-gauchebas,
        img#illustrations-outils-droitehaut,
        img#illustrations-outils-droitebas {
          position: absolute;
          width: auto;
          height: 15%;
        }

        div.titreFlex {
          margin-top: 0;
        }

        ul {
          margin-bottom: 0;
          padding: 0 20px 0 70px;
        }
      }

      &.regles {
        padding: 20px 0 50px !important;
        height:fit-content;

        h2 {
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .atelier {
    section {
      &.galerie {
        .images {
          height: 85vh;
          -ms-grid-columns: 50% 50%;
          grid-template-columns: 50% 50%;
          -ms-grid-rows: 33.3% 33.3% 33.3%;
          grid-template-rows: 33.3% 33.3% 33.3%;
          grid-template-areas: "div1 div2" "div3 div4" "div5 div6";

          img#doodle1 {
            height: 30%;
            -ms-grid-column: 2;
            grid-column: 2;
            left: -10px;
            top: 10%;
          }

          img#doodle2 {
            -ms-grid-column: 2;
            grid-column: 2;
            -ms-grid-row: 3;
            grid-row: 3;
            top: -5px;
            left: -50px;
          }
        }

      }
    }
  }

}

@media screen and (max-width: 700px) {
  .atelier {

    section.regles {
      h3 {
        margin-bottom: 5px;
      }
      div.nav-regles {
        a {
          padding: 7px;
        }
      }

      .les-regles {
        margin: 0 20px;
        flex-direction: column;

        .une-regle {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }

}

@media screen and (min-width: 600px) {
  .atelier {
    .atelier{
      .info{
        .bas{
          .wrapper{
            .points{
              .flex-droit{
                padding-left: 20px
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .atelier {
    section.info {
      .bas {
        padding: 0 20px;

        .wrapper {
          align-items: center;

          .points {
            max-width: 400px;
            align-items: center;
            flex-direction: column;
            padding: 0;

            .flex {
              width: 100%;

              &.flex-gauche {
                margin-right: 0;
              }

              &.flex-droit {
                margin-left: 0;
              }

              div {
                width: 100%;
              }
            }
          }
        }
      }
    }

    section.techniques {

      img#illustrations-fonderie-mobile, img#illustrations-soudure-mobile {
        width: 90%;
        left: 5%;
      }

      div.texte {

        div.texte {
          .liste-prix {

            .non-membres, .membres {
              width: 100% !important;
            }
          }

        }
      }

      .texte {
        padding-right: 0;

        .general {

          .textAnim {
            padding-right: 0;
          }
        }
        .liste-prix {
          flex-direction: column;

          .materiaux {
            width: 100%;
            margin-bottom: 20px;
          }

          .temps {
            border-top: 3px solid transparent;
            border-image: url("../images/ligne-droite-orange-mobile.svg") 30 stretch;
            padding-top: 20px;
            max-width: 450px;
            width: 100%;

            h3 {
              margin: 0;
            }
          }

          .coulee {
            border-top: 3px solid transparent;
            border-image: url("../images/ligne-droite-orange-mobile.svg") 30 stretch;
            padding-top: 20px;
            max-width: 450px;
            width: 100%;
            margin: 30px 0 60px;
          }

          .bouton {
            margin: auto;
          }
        }
      }

      &.soudure {
        .texte {
          .general {
            div {
              h2 {
                top: -300px;
              }
              img {
                top: -250px;
              }
            }
          }
          .liste-prix {
            .membres {
              border-left: none;
              border-top: 3px solid transparent;
              border-image: url("../images/ligne-droite-rouge-mobile.svg") 30 stretch;
              width: 100%;
              max-width: 450px;
              padding: 20px 0 0;

              .un-prix.liste {
                .prix {
                  .par {
                    width: 100%;
                  }
                }
              }
            }

            .non-membres {
              margin: 0;
              width: 100%;
              max-width: 450px;
            }
          }
        }
      }
    }

    section.equipement {

      img#illustrations-outils-gauchehaut,
      img#illustrations-outils-gauchebas,
      img#illustrations-outils-droitehaut,
      img#illustrations-outils-droitebas {
        margin: 0;
        position: absolute;
        width: auto;
        height: 12%;
      }

      img#illustrations-outils-gauchebas,
      img#illustrations-outils-droitebas {
        bottom: 0;
      }
      img {
        margin-bottom: 40px;
      }

      ul {
        padding: 0 0 0 20px;

        li {
          width: 100%;
        }
      }
    }

  }
}

@media screen and (max-width: 426px) {
  .atelier {
    section.techniques.soudure {
      div.texte {
        div.texte {
          .general {
            h2 {
              top: -350px;
            }
            img {
              top: -300px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 880px) and (min-width: 1500px) {
  section.regles {
    min-height:880px;
    padding-top: 0;
  }
}


//Styles pour Firefox
@-moz-document url-prefix() {
  @media screen and (max-width: 900px) {
    .atelier{
      .info{
        .bas{
          .wrapper{
            .points{
              .flex{
                div{

                  img{
                    height: 25px;
                  }

                }

              }
            }
          }
        }
      }
    }
  }

}



//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .atelier {
    .info {
      .haut {
        max-width: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          max-width: 80vw;
        }
      }

    }

    section {

      &.galerie {
        display: flex;
        flex-direction: column;

        .plan {
          align-self: center;
        }
      }
    }
  }
}

