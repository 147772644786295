.apropos {
  header {
    background-image: url("../images/bg-apropos.png");
  }

  section {
    height: 100vh;

    h2 {
      margin: 30px 0
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    .wrapper {
      max-width: 1200px;
    }

    &.mission {
      overflow: hidden;
      position: relative;
      background-color: $orange-lc;

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        .mots-cles {
          background: url("../images/doodle-titres.svg") no-repeat 0 100%;
          background-size: contain;
          padding-left: 72px;
          margin-left: -72px;
          height: fit-content;
          padding-bottom: 30px;
          margin-bottom: -30px;

          h2:last-child {
            margin-bottom: 0;
          }
        }
      }

      p {
        max-width: 900px;
        margin-left: 0;
        margin-top: 110px;
      }

      h2 {
        color: $black-lc;
        font-size: rem(98);
      }

      img#illustration1-gauche {
        position: absolute;
        left: 0;
        height: 100%;
        width: auto;

      }

      img#illustration1-droite {
        transform: rotate(180deg);
        position: absolute;
        right: 0;
        height: 100%;
        width: auto;
      }
    }


    &.aspiration {
      background: $orange-lc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      & > img {
        position: absolute;
        width: 34vw;
        height: auto;

        &#illustration4-haut {
          top: 0;
        }

        &#illustration4-bas {
          bottom: 0;
          transform: rotate(180deg);
        }
      }

      .titre {
        text-align: center;
        width: 45%;
        margin-bottom: 50px;

        img {
          width: 300px;
          height: auto;
        }

        h2 {
          margin: 0 0 30px;
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 60%;
        list-style: url("../images/doodle-liste.svg");

        li {
          width: 45%;
          padding: 20px 20px;

          span {
            position: relative;
            top: -15px;
          }
        }
      }
    }

    &.histoire {
      overflow: hidden;
      position: relative;
      background-color: $bleu-lc;

      p {
        margin-left: 100px;
        z-index: 1;
        max-width: 712px;

        &:first-of-type {
          margin-top: 64px;
        }
      }

      h2 {
        color: $black-lc;
        z-index: 1;
        margin-bottom: 4px;
      }

      img.doodle {
        height: 31px;
        max-width: 488px;
      }

      img#illustration2-gauchehaut {
        position: absolute;
        width: auto;
        top: 0;
        left: 0;
        transform: rotate3d(0, 0, 1, 180deg);
      }

      img#illustration2-gauchebas {
        position: absolute;
        width: auto;
        bottom: 0;
        left: -15px;
        transform: rotate3d(0, 1, 0, 180deg);
      }

      img#illustration2-droitehaut {
        position: absolute;
        //height: 40%;
        width: auto;
        top: 0;
        right: -15px;
        transform: rotate3d(1, 0, 0, 180deg);
      }

      img#illustration2-droitebas {
        position: absolute;
        //height: 40%;
        width: auto;
        bottom: 0;
        right: 0;
      }
    }

    /* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

    &.galerie {
      position: relative;
      padding: 0;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 33.3% 33.3% 33.3%;
      grid-template-columns: 33.3% 33.3% 33.3%;
      -ms-grid-rows: 50% 50%;
      grid-template-rows: 50% 50%;
      grid-template-areas: "div1 div2 div3" "div4 div5 div6";

      img#doodle1 {
        position: absolute;
        height: 60%;
        width: auto;
        -ms-grid-column: 2;
        grid-column: 2;
        left: -24px;
        top: 20%;
      }

      img#doodle2 {
        position: absolute;
        width: 50%;
        height: auto;
        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 2;
        grid-row: 2;
        top: -9px;
        left: calc(-25% - 6px);
      }

      div#image1 {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        background-image: url("../images/galerie-img1.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div1;
      }

      div#image2 {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        background-image: url("../images/galerie-img2.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div2;
      }

      div#image3 {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        background-image: url("../images/galerie-img3.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div3;
      }

      div#image4 {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        background-image: url("../images/galerie-img4.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div4;
      }

      div#image5 {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        //background-color: hotpink;
        background-image: url("../images/galerie-img5.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div5;
      }

      div#image6 {
        -ms-grid-row: 2;
        -ms-grid-column: 3;
        //background-color: palevioletred;
        background-image: url("../images/galerie-img6.png");
        background-size: cover;
        background-position: 50% 50%;
        grid-area: div6;
      }
    }

    &.inclusion {
      position: relative;
      overflow: hidden;
      flex-direction: row;
      align-items: center;

      .wrapper {
        width: fit-content;
        display: flex;
        align-items: flex-start;

        & > div {
          width: 610px;
        }

        .mots-cles {
          margin-right: 50px;
          position: relative;

          h2 {
            font-size: rem(98);
            color: $orange-lc-dark;
            padding-bottom: 40px;
            margin: 0;

            &:first-child {
              color: $bleu-lc-dark;
            }

            &:nth-child(3) {
              color: $rouge-rose-lc;
            }
          }

          img {
            height: 30px;
            width: auto;
            position: absolute;
          }
        }

        p {
          min-width: 610px;
          margin-top: 32px;
        }
      }

      img#illustration3-haut {
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        transform: rotate(180deg);
      }

      img#illustration3-bas {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
      }
    }

    &.batiment7 {
      background-image: url("../images/batiment7.png");
      background-position: center;
      background-blend-mode: darken;
      background-size: cover;
      position: relative;
      //filter: brightness(30%);
      margin: 0;
      width: 100vw;

      img.doodle {
        max-width: 360px;
      }

      div.titre {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      a.bouton {
        border: solid 3px #ffffff;
        padding: 10px 22px;
        cursor: pointer;
        display: flex;
        width: fit-content;
        margin-left: 100px;
        transition: 0.3s all;
        margin-top: 34px;

        &:hover {
          background-color: #ffffff;
          border-color: #ffffff;
          text-decoration: none;

          p {
            color: $black-lc;
          }
        }

        p {
          width: fit-content;
          margin: 0;
          transition: 0.3s all;
        }
      }

      p {
        margin-left: 100px;
      }

      h2, p {
        color: $white-lc
      }
    }

    &.equipe {
      position: relative;
      height: 100vh !important;
      width: calc(100vw - 100px) !important;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: row;
      padding-left: 100px;
      background: $orange-lc;

      img.doodle {
        position: absolute;
        right: 39vw;
        height: 630px;
        width: auto;
        top: 25%;
      }

      .texte {
        padding: 0 0 0 10px;
        margin: 0;
        width: 712px;
        margin-top: 35vh;

        p.role {
          font-weight: 700;
        }

        .haut {
          top: 12vh;
          position: absolute;

          h3 {
            background: url("../images/doodle-section.png") no-repeat 0 100%;
          }
          .nav-fleches{
            display: flex;
            .fleches-nav-haut{
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                height: 48px;
                width: auto;
                cursor: pointer;
                filter: brightness(0.2);
                &.suiD{
                  transform: rotate(180deg);
                }
                &:hover{
                  filter: brightness(0.5);
                  transition: all 0.4s;
                }
              }
            }
          }

          .nav-section {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-left: 3px solid $black-lc;

            .bouton {
              padding: 10px 22px;
              border: 3px solid $black-lc;
              border-left: none;
              cursor: pointer;

              p {
                margin: 0;
              }

              &:hover {
                transition: all 0.5s;
                text-decoration: none;
                background: $black-lc;
                color: $orange-lc;
              }

              &.actif {
                background: $black-lc;
                color: $orange-lc;
              }
            }
          }
        }

        .centre {
          display: none;

          h2 {
            font-size: rem(55);
            letter-spacing: rem(5);
            padding-right: 28px;
            padding-top: 28px;
            margin-top: -28px;
            padding-left: 28px;
            margin-left: -28px;
            padding-bottom: 28px;
            margin-bottom: -28px;
            //background: url("../images/doodle-titres.png") no-repeat 0 100%;
            background-position-y: 0;
          }

          p {
            width: 530px;
            margin-bottom: 32px;
            font-size: rem(15);

            &:nth-child(3) {
              margin-top: 40px;
            }

            &:nth-child(4) {
              margin-bottom: 50px;
            }
          }

          .bouton {
            padding: 10px 22px;
            border: 3px solid $black-lc;

            p {
              margin: 0;
              width: auto;
              display: inline;
            }

            &:hover {
              transition: all 0.5s;
              text-decoration: none;
              background: $black-lc;
              color: $orange-lc;
            }
          }
        }
      }

      img {
        height: 100vh;
        width: 40vw;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .apropos {
    section {
      &.equipe {
        .imageSection {
          width: 30vw;
          object-fit: cover;
        }

        .doodle {
          right: calc(30vw - 24px) !important;
        }
      }

      .wrapper {
        max-width: 700px;
      }

      &.aspiration {
        .titre {
          width: 65%;
        }
        ul {
          width: 85%;
        }
      }

      &.histoire {
        img {
          height: 25%;
          width: auto;
        }
      }

      &.inclusion {
        .wrapper {
          max-width: calc(100vw - 200px);

          div {
            width: fit-content;

            h2 {
              font-size: rem(72) !important;
            }

            img {
              width: 400px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .apropos {
    section {
      &.equipe {

        .texte {
          width: 800px;

          .centre {
            p {
              width: 480px;
            }

            h2 {
              width: 480px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) and (min-width: 900px) {
  .apropos {
    section {
      &.inclusion {
        .wrapper {
          max-width: calc(100vw - 200px) !important;
          align-items: center !important;

          div {
            width: fit-content !important;

            h2 {
              font-size: rem(60) !important;
            }

            img {
              width: 320px !important;
            }

            p {
              max-width: 600px;
              min-width: 0 !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .apropos {
    section:not(.video) {
      padding: 200px 0;
      &.aspiration {
        padding: 120px 0 80px 0;

        & > img {
          width: 60vw;
        }

        .titre {
          width: 80%;
        }

        ul {
          width: 90%;

          li {
            width: 48%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .apropos {
    section {
      &.equipe {
        width: 100vw !important;
        height: auto !important;
        padding-bottom: 50px !important;

        .texte {
          margin-top: 32vh !important;
        }
      }
    }
  }
}

@media screen and (max-height: 880px) {
  .apropos {
    section {

      &.mission {
        .wrapper {
          max-width: calc(100vw - 280px);
          align-items: center !important;
          height: 360px;
        }

        #illustration1-haut, #illustration1-bas {
          display: none !important;
          left: 70% !important;
          max-height: 50%;
          max-width: 50%;
        }
        #illustration1-gauche, #illustration1-droite {
          display: block !important;
        }
      }

      &.inclusion {
        #illustration3-haut, #illustration3-bas {
          max-width: 80%;
        }
      }

      &.equipe {
        margin-left: 0;
        width: auto;
        min-height: 600px;
        padding: 30px 0;

        img.doodle {
          height: 400px;
          right: 30vw;
          margin-right: 8px;
        }

        .imageSection {
          min-height: 760px;
        }

        .texte {
          margin-top: 100px !important;

          .haut {
            margin-top: -60px;
            position: relative;
            top: 0;
            padding-bottom: 30px;
          }

          .centre {
            p:nth-child(4) {
              margin-bottom: 60px;
            }
          }
        }
      }

      &.batiment7 {
        .wrapper {
          max-width: 80%;
          align-items: center;

          a {
            margin-left: 0;
          }

          img {
            max-width: 320px;
            max-height: 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .apropos {
    section:not(.video) {
      padding: 200px 0;

      &.mission {
        #illustration1-haut, #illustration1-bas {
          display: block !important;
        }

        #illustration1-gauche, #illustration1-droite {
          display: none !important;
        }

        .wrapper {
          max-width: calc(100vw - 32px);
          align-items: center !important;

          p {
            max-width: 600px;
          }
        }
      }

      &.galerie {
        position: relative;
        padding: 0;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
        -ms-grid-rows: 33.3% 33.3% 33.3%;
        grid-template-rows: 33.3% 33.3% 33.3%;
        grid-template-areas: "div1 div2" "div3 div4" "div5 div6";
      }

      &.equipe {
        background-color: transparent !important;
        margin: 0 !important;
        justify-content: flex-start;
        width: auto !important;
        padding: 10vh 0;

        .texte {
          margin-bottom: 328px;
          max-width: calc(100vw - 32px);
          margin-left: 24px;
          margin-top: 20vh;

          .mobile {
            h2 {
              padding-bottom: 15px;
              width: 100vw;
              font-size: rem(45);
            }
          }

          div {
            & > p {
              margin-top: rem(16) !important;
              margin-bottom: rem(24) !important;
              max-width: calc(100vw - 48px);
            }
          }
        }

        .haut {
          margin-top: -60px;
          position: relative;
          top: 0;
          padding-bottom: 30px;
          h3 {
            color: $white-lc;
            background: none !important;
          }
          .nav-fleches{
            .fleches-nav-haut{
              img{
                filter: unset;
                &:hover{
                  filter: brightness(0.5);
                  transition: all 0.4s;
                }
              }
            }
          }

          .nav-section {
            border-color: $white-lc;
            a.bouton {
              color: $white-lc;
              border-color: $white-lc !important;

              &.actif {
                background: $white-lc !important;
                color: $black-lc !important;
              }

              &:hover {
                background: $white-lc !important;
                color: $black-lc !important;
              }
            }
          }

        }

        .centre {
          flex-direction: column;
          color: $white-lc;

          .mobile {
            align-items: flex-start !important;

            h2 {
              color: $white-lc;
            }

            .imageSection {
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              height: 100vh;
              width: 100vw;
              filter: brightness(65%);
            }
          }

          a.bouton {
            border-color: $white-lc !important;
            color: $white-lc !important;
            width: fit-content;
            padding: 6px 28px !important;

            &:hover {
              background-color: $white-lc !important;
              color: $black-lc !important;
            }

            p {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 2px !important;
            }
          }
        }
      }

      &.batiment7 {
        .wrapper {
          max-width: 100%;
          margin: 0 20px;

          .bouton {
            margin-left: 0;
            align-self: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px), screen and (max-height: 880px) {
  .apropos {
    section:not(.video) {
      height: inherit;
      padding: 200px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.equipe {
        .texte {
          margin-top: 50px;
        }
      }

      &.galerie {
        display: grid;

        img#doodle1, img#doodle2 {
          display: none;
        }
      }

      .wrapper {
        max-width: 450px;
      }

      h2 {
        margin: 20px 0
      }

      &.mission {
        .wrapper {
          align-items: flex-start;
          justify-content: center;

          .mots-cles {
            padding-left: 16px;
            margin-left: -16px;
            padding-bottom: 8px;
            margin-bottom: -8px;

            h2 {
              font-size: rem(36);
              background: none;
            }
          }

          p {
            margin-top: 32px;
          }
        }

        p {
          margin-left: 0;
        }
        h2 {
          color: $rouge-rose-lc;
        }
        h2:last-child {
          background: url("../images/doodle-titres.png") no-repeat 0 100%;
        }

        img#illustration1-gauche {
          display: none;
        }

        img#illustration1-droite {
          display: none;
        }

        img#illustration1-haut {
          position: absolute;
          top: 0;
          width: 90%;
          left: 50%;
          margin-left: -45%;
        }

        img#illustration1-bas {
          position: absolute;
          bottom: 0;
          transform: rotate(180deg);
          width: 90%;
          left: 50%;
          margin-left: -45%;
        }
      }

      &.inclusion {
        .wrapper {
          flex-direction: column;
          max-width: calc(100vw - 48px);

          p {
            min-width: auto;
          }

          .mots-cles {
            margin: 0;

            h2 {
              font-size: rem(36) !important;
              margin: 0;
              padding-bottom: 0;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img {
              position: relative;
              width: 202px !important;
              height: auto;
              padding: 0;
              margin: 0;
            }

            div {
              width: 100%;
            }
          }

          div {
            width: calc(100vw - 48px);
          }
        }
      }



      &.histoire {
        p {
          margin-left: 0;
          max-width: 80vw;
        }
        img#illustration2-gauchehaut {
          height: 12%;
        }
        img#illustration2-gauchebas {
          height: 12%;
          bottom: 0;
        }
        img#illustration2-droitehaut {
          height: 12%;
          top: 0;
        }
        img#illustration2-droitebas {
          height: 12%;
        }

        .wrapper {
          max-width: calc(100vw - 64px);
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            text-align: center;
          }

          img {
            width: 260px;
            margin: 0;
            max-width: 100%;
          }
        }
      }

      /* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

      &.galerie {
        padding: 0 !important;
        height: 100vh;
        -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
        -ms-grid-rows: 33.3% 33.3% 33.3%;
        grid-template-rows: 33.3% 33.3% 33.3%;
        grid-template-areas: "div1 div2" "div3 div4" "div5 div6";

        img#doodle1 {
          height: 30%;
          -ms-grid-column: 2;
          grid-column: 2;
          left: -10px;
          top: 10%;
        }

        img#doodle2 {
          -ms-grid-column: 2;
          grid-column: 2;
          -ms-grid-row: 3;
          grid-row: 3;
          top: -5px;
          left: -50px;
        }
      }

      &.inclusion {
        flex-direction: column;
        .mots-cles {
          margin-right: 0;
          text-align: center;

        }

        img#illustration3-haut {
          width: 150%;
        }

        img#illustration3-bas {
          width: 150%;
        }
      }

      &.batiment7 {
        img.doodle {
          width: 260px;
        }

        .wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            margin-left: 0;
          }

          h2 {
            text-align: center;
            align-self: center;
          }

          img {
            margin: auto;
            margin-bottom: 34px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .apropos {

    section.aspiration:not(.video) {
      .titre {
        margin-bottom: 0;

        img{
          width: 200px;
        }
      }
      ul {
        flex-direction: column;
        width: 100%;
        li {
          width: 100%;
          padding: 10px 20px;
        }
      }
    }

    section.equipe {
      padding-left: 0;
      padding-right: 0;
    }

    section:not(.video).mission {
      padding: 80px 0;

      img#illustration1-haut, img#illustration1-bas {
        max-width: 80vw !important;
        max-height: 80vw !important;
        margin-left: 10vw;
        left: 0 !important;
      }

      .wrapper {
        height: fit-content;
      }
    }

    section.inclusion {
      padding-top: 80px;
      padding-bottom: 80px;

      img#illustration3-haut, img#illustration3-bas {
        max-width: 150vw !important;
        width: 150vw !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .apropos section.equipe .texte .haut .nav-section .bouton {
    padding: 5px 15px;
  }
}

@media screen and (max-width: 500px) {
  .apropos {
    section:not(.video) {
      .wrapper {
        max-width: 315px;
      }

      &.batiment7 {
        .wrapper {
          & > a {
            margin: auto;
            margin-top: 40px;
          }
        }
      }

      &.inclusion {
        img#illustration3-haut, img#illustration3-bas {
          max-width: 200vw !important;
          width: 200vw !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .apropos {
    section.batiment7 {
      .wrapper {
        img.doodle {
          max-width: 500px !important;
        }
      }
    }

    section.equipe {
      img.doodle {
        right: 38.5vw;
      }
    }
  }
}

@media screen and (max-height: 880px) and (min-width: 900px) {
  .apropos section.equipe {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    padding-left: 100px;
  }
}

//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .apropos {

    section{
      .wrapper{
        width: 100%;
      }
    }

    section.mission {
      .wrapper {
        width: 100%;

        div, div.mots-cles {
          width: 100% !important;
        }
      }
    }

    section.aspiration {

      img {
        left: 33vw;
      }
    }

    section.inclusion{

      img#illustration3-haut, img#illustration3-bas{
        left:0;
      }
      .wrapper{
        width: 80%;

        .mots-cles{
          margin-right: 0;
        }

        div{
          width: auto;

          p{
            margin-right: 20vw;
          }
        }
      }
    }

    section.batiment7{
      .wrapper{
        p{
          max-width: 80vw;
        }

        a.bouton{
          max-width: 400px;
        }
      }

    }

  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 900px) and (min-height: 880px){
  .apropos {
    section {
      &.histoire{
        p{
          margin-left: 200px;
        }

        h2{
          margin-left: 50px;
        }
      }

      &.inclusion{

        .wrapper{
          max-width: 80vw;

          p{
            min-width: auto;
            margin-right: 10vw;
            margin-left: 20px;
          }
        }
      }

    }
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-height: 880px) {

  .apropos {
    section{


      &.galerie{
        display: none;
      }

      &.inclusion{

        .wrapper{

          p{

            max-width: 80vw;
          }
        }
      }
    }



  }

}



@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 900px) and (max-height: 880px) {

  .apropos {
    

    section{
      &.mission {
        width: auto;
      }
      
      &.aspiration {

        img {
          left: 20vw;
        }

        ul {
          li {
            width: auto;
          }
        }
      }

    }



  }

}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 600px) {

  .apropos {


    section.aspiration {

      img {
        left: 30vw;
        width: 40vw;
      }

    }
  }

}
