// STYLE DE BASE SUR LA BALISE BODY

body{

  @include font($ff-normal, $fz-normal, $color-text-normal, $lh-normal);

  background-color: $color-bg-page;

  font-size: rem(18);
}

@media screen and (max-width: 1500px){
  body{font-size: rem(16);}
}

@media screen and (max-width: 800px){
  body{font-size: rem(14); line-height: 150%;}
}

html{
  overflow-x: hidden;
}



