.nousjoindre {
  section {
    height: 100vh;
    display: flex;

    &.infos-carte {
      background-color: $bleu-lc;
      .informations {
        margin: 0 150px 0 auto;
        z-index: 2;
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div.titre {
          width: 60%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img#doodle-rouge-vertical {
            height: 230px;
            width: auto;
          }
          img#doodle-rouge-horizontal{
            display: none;
          }

          h1 {
            //width:50%;
            line-height: 110%;
            margin: 0 0 0 30px;
          }
        }

        ul {
          width: 60%;

          li {
            list-style: url("../images/doodle-zigzag-rouge-small.svg");
            img{display: none;}

            div.adresse{
              div{
                margin-top: 20px;
                span{
                  font-weight: $fw-bold;
                }
              }
            }

            div.horaire{
              div{
                display: flex;
                span{font-weight: $fw-bold;}
              }
            }
          }

          h2 {
            margin: 40px 0;
            span {
              position: relative;
              top: 15px;
            }
          }

          p {
            margin: 0;
          }
        }
      }
    }


    div.map {
      width: 45%;
      background-color: $bleu-lc;
    }

    &.formulaire-courriel{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1200px;

        .titre{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          h2{
            margin: 20px;
          }
          img{
            width: 300px;
            height: auto;
            align-self: center;
          }
        }

      }

    }
  }

}

@media screen and (max-width: 1300px){
  .nousjoindre{
    section{
      &.infos-carte{
        .informations{
          ul{
            li{
              div.horaire{
                div{
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .nousjoindre {
    section {
      height: inherit;
      flex-direction: column;
      
      &.infos-carte{

        background-color: #fff;
        .informations {
          padding: 160px 0;
          //padding: 70px 20px;
          margin: 0;
          width: 100%;
          justify-content: center;
          align-items: center;

          div.titre {
            width: 100%;
            text-align: center;
            flex-direction: column;
            justify-content: center;

            h1 {
              margin: 0;
              width: 70%;
              padding: 10px 0;
            }

            img#doodle-rouge-vertical {
              display: none;
            }
            img#doodle-rouge-horizontal{
              display: block;
              width: 300px;
              height: auto;
              margin: 0 0 20px 0;
            }
          }

          ul {
            text-align: center;
            width: 100%;
            padding: 0;

            li {
              list-style: none;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 30px 0;

              img{
                display: block;
                width: 60px;
                height: auto;
              }

              div.horaire{
                div{
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  p{
                    padding: 0 10px;
                  }
                }
              }

              div.adresse{
                p{
                  padding: 0 10px;
                }
              }


              h2{
                margin: 20px;
                font-size: rem(36);
              }
            }


            h2 span{
              top: 0;
              padding: 0 10px;
            }

          }
        }

        div.map {
          height: 50vh;
          width: 100%;
        }
      }

      &.formulaire-courriel{
        padding: 70px 20px;
        background-color: $bleu-lc;
        min-height: auto;

        .wrapper{
          width: 100%;
          img{
            width: 250px;
          }

          .titre h2{
            text-align: center;
          }
        }


      }
    }


    footer {
      overflow: hidden;
    }
  }


}