body {
  width: 100vw;
}

#fixed-menu-button {
  position: fixed;
  z-index: 3;
  color: #fff;
  top: 60px;
  right: 10px;

  font-family: Staatliches, sans-serif;
  font-size: rem(36);
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
  transform: rotate(90deg);
}

header {
  height: 100vh;

  overflow: hidden;
  position: relative;

  //-----------VIDEO

  #bg-image {
    z-index: -1;
    position: relative;
    width: 100vw;
    height: 100vh;
    display: none;
    background-color: #000;
    //background-image: url("../images/bg-alternatif.png");
    background-size: cover;
    background-position: 50% 50%;
    //object-fit: cover;
    img {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      object-fit: cover;
    }
  }

  #myVideo {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: -1;
    width: 100%;
    height: auto;
    filter: brightness(70%) grayscale(40%);
  }
  @media (min-aspect-ratio: 16/9) {
    #myVideo {
      width: 100%;
      height: auto;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    #myVideo {
      width: auto;
      height: 100%;
    }
  }
  @media (max-width: 500px) {
    #videoBG {
      display: none;
    }
  }

  .content {
    position: absolute;
    bottom: 30%;
    right: 0;
    color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;

    img.logoDoodle {
      width: auto;
      padding-right: 25px;
    }

    img.imgLogo {
      width: 100%;
      height: auto;
      padding-right: 25vw;
    }
  }
}

.accueil {
  section {
    position: relative;
    height: fit-content;
    width: calc(100vw - 100px) !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 100px;
    background: $bleu-lc;

    .fleches-nav {
      display: none;
      position: absolute;
      width: calc(100vw - 100px);
      top: calc(50vh - 40px);

      justify-content: space-between;
      align-items: center;

      img {
        cursor: pointer;
        height: 80px;
        width: auto;
        &.sui{
          transform: rotate(180deg);
        }
      }
    }

    .nav-fleches{
      display: flex;
      align-items: center;
    }

    .fleches-nav-haut{
      display: flex;
      align-items: center;
      img{
        height: 48px;
        width: auto;
        cursor: pointer;
        filter: brightness(0.2);
        &.suiD{
          transform: rotate(180deg);
        }
        &:hover{
          filter: brightness(0.5);
          transition: all 0.4s;
        }
      }
    }

    .texte {
      width: 50vw;
      max-width: 712px;
      //min-height:900px;
      padding-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .haut {
        padding-bottom: 100px;
        padding-left: 20px;

        h3 {
          background: url("../images/doodle-section.png") no-repeat 0 100%;
          margin-top: 100px;
        }

        .nav-section {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          border-left: 3px solid $black-lc;

          .bouton {
            padding: 10px 22px;
            border: 3px solid $black-lc;
            border-left: none;
            cursor: pointer;

            p {
              margin: 0;
            }

            &:hover {
              transition: all 0.5s;
              text-decoration: none;
              background: $black-lc;
              color: $bleu-lc;
            }

            &.actif {
              background: $black-lc;
              color: $bleu-lc;
            }
          }
        }
      }

      .centre {
        display: none;
        margin: auto;
        margin-left: 100px;

        h2 {
          padding-top: 28px;
          margin-top: -28px;
          padding-left: 28px;
          margin-left: -28px;
          padding-bottom: 28px;
          margin-bottom: -28px;
          background: url("../images/doodle-titres.png") no-repeat 0 70%;
          background-position-y: 0;
          font-size: rem(60);
        }

        p {
          max-width: 530px;
          margin: 40px 0 65px;

          &.textAnim {
            padding-right: 15px;
          }
        }

        .bouton {
          padding: 10px 22px;
          border: 3px solid $black-lc;

          p {
            margin: 0;
            width: auto;
            display: inline;
          }
          &:hover {
            transition: all 0.5s;
            text-decoration: none;
            background: $black-lc;
            color: $bleu-lc;
          }
        }
      }
    }

    img {
      height: 100vh;
      //min-height:100%;
      width: 40vw;
      object-fit: cover;
    }

    &.actualites {
      img.cote {
        position: absolute;
        right: 40vw;
        width: 48px;
        height: auto;
        margin-right: -24px;
        top: 26vh;
      }
    }

    &.techniques {
      background: $orange-lc;

      img {
        &.float {
          position: absolute;
          height: 20px;
          width: auto;
          left: 0;
          bottom: 140px;
        }

        &.cote {
          position: absolute;
          right: 40vw;
          width: 20px;
          height: auto;
          margin-right: -10px;
          top: 100px;
        }
      }

      .texte {
        .haut {
          h3 {
            background: url("../images/doodle-section2.png") no-repeat 0 100%;
          }
          .nav-section {
            .bouton {
              &:hover {
                color: $orange-lc;
                cursor: pointer;
              }
              &.actif {
                background: $black-lc;
                color: $orange-lc;
              }
            }

          }
        }
        .centre {
          display: none;
          h2 {
            padding-top: 28px;
            margin-top: -28px;
            padding-left: 28px;
            margin-left: -28px;
            padding-bottom: 28px;
            margin-bottom: -28px;
            background: url("../images/doodle-titres2.png") no-repeat 0 100%;
            background-position-y: 0;
          }
          .bouton {
            &:hover {
              color: $orange-lc;
            }
          }
        }
      }
    }

    &.services {
      background: $rouge-lc;

      img.cote {
        position: absolute;
        right: 40vw;
        width: 48px;
        height: auto;
        margin-right: -24px;
        top: 26vh;
      }

      .texte {
        .haut {
          h3 {
            background: url("../images/doodle-section3.png") no-repeat 0 100%;
          }
          .nav-section {
            .bouton {
              white-space: nowrap;

              &:hover {
                color: $rouge-lc;
                cursor: pointer;
              }
              &.actif {
                background: $black-lc;
                color: $rouge-lc;
              }

              p {
                white-space: nowrap;
              }
            }
          }
        }
        .centre {
          display: none;

          h2 {
            padding-top: 28px;
            margin-top: -28px;
            padding-left: 28px;
            margin-left: -28px;
            padding-bottom: 28px;
            margin-bottom: -28px;
            background: url("../images/doodle-titres3.png") no-repeat 0 100%;
            background-position-y: 0;
          }
          .bouton {
            &:hover {
              color: $rouge-lc;
            }
          }
        }
      }
    }

    /* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

    &.devenir-membre {
      width: 100% !important;
      height: 100vh;
      margin: 0;
      padding: 50px 100px;
      background-color: $orange-lc;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > img.deco {
        width: auto;
        height: 76vh;
        top: 50vh;
        margin-top: -38vh;

        &.gauche {
          position: absolute;
          left: 0;
        }

        &.droite {
          position: absolute;
          right: 0;
          transform: rotate3d(0, 1, 0, 180deg);
        }
      }

      .wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        max-width: 1200px;

        div.titre {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          img {
            width: 250px !important;
            height: auto !important;
            margin: 0;
          }
          h2 {
            margin: 20px 0;
            width: 65%;
            text-align: center;
          }
        }

        div.contenu {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin: 80px 0 30px;
          justify-content: center;

          ul, p {
            width: 45%;
            margin: 0;
          }

          ul {
            padding: 0 25px;
            li {
              margin: 20px 0;
              list-style: url("../images/crochet-liste-rouge.svg");
              &:first-child {
                margin-top: 0;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        h3 {
          margin: 0;
          padding: 0;
        }
        span {
          font-size: rem(30);
          font-weight: $fw-bold;
        }

        .bouton {
          padding: 10px 22px;
          border: 3px solid $black-lc;
          cursor: pointer;
          display: inline;
          -webkit-transition: 0.5s ease;
          -o-transition: 0.5s ease;
          transition: 0.5s ease;
          margin: 0 0 20px;
          p {
            margin: 0;
            width: auto;
            display: inline;
          }
          &:hover {
            background: $black-lc;
            color: $orange-lc;
            text-decoration: none;
          }
        }
      }

    }
  }
}

//.accueil header img#lignes1 {
//  position: absolute;
//  top:0;
//  left:0 !important;
//  transform: rotate(0deg) !important;
//  width:auto;
//  height:auto;
//
//
//}

// HEIGHT

@media screen and (max-width: 1600px) {
  .accueil {
    section {
      &.devenir-membre {
        & > img.deco {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .accueil section {
    &.devenir-membre {
      .wrapper {
        div.titre {
          h2 {
            width: auto;
          }
        }

        div.contenu {
          margin: 50px 0;
        }
      }
    }

    .texte .haut {
      padding-bottom: 60px;

      h3 {
        margin-top: 60px;
      }
    }
  }
}

@media screen and (max-height: 880px) {
  .accueil {
    header {
      .content {
        bottom: 50%;
        margin-bottom: -90px;

        img.imgLogo {
          width: 70%;
          padding-right: 0;
        }
      }
    }

    section {
      height: 900px !important;

      img {
        height: 900px;
        margin: 0;
        padding: 0;
        width: 45vw;

        &.imageSection {
          //min-height:900px;
        }
      }

      &.devenir-membre {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .accueil {
    header {
      height: 100vh;

      #bg-image {
        display: block;
      }

      #myVideo {
        display: none;
      }

      .content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    section.actualites .texte .centre, section.techniques .texte .centre, section.services .texte .centre {
      margin-right: 0 !important;
    }

    section {
      position: relative;
      margin-left: 0;
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: fit-content !important;
      padding-bottom: 54px;
      padding-top: 0;

      .fleches-nav {
        display: flex;
        width: 100vw;
        top: 287px;
        z-index: 20;
      }

      .fleches-nav-haut{
        display: none;
      }

      .mobile {
        h2 {
          z-index: 1;
          position: absolute;
          top: 50%;
          bottom: 50%;
          color: #f2f0f2;
        }
      }

      img.imageSection {
        filter: brightness(0.4);
        width: calc(100% - 32px);
        height: 280px;
        object-fit: cover;
        margin: 16px;
      }

      div {
        position: relative;

        .doodleSeparation {
          position: absolute;
          width: 50%;
          height: auto;

          bottom: 5px;
          left: 25%;
          margin: 0;
          &.zigzagdoodle {
            width: 35%;
            left: 32%;
          }
        }
      }

      .texte {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        height: fit-content;
        padding-bottom: 0;

        .haut {
          width: fit-content;
          position: relative;
          top: auto;
          padding-left: 16px;
          padding-bottom: 14px;

          .nav-section {
            flex-wrap: wrap;
            border-left: solid 2px #2b2b2b;

            &:first-child {
              border-left: none;
            }

            .bouton.actualites {
              width: 38px;

              p {
                padding-top: 2px;
                padding-left: 4px;
              }
            }

            .bouton {
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              border-width: 2px;
              border-left: none;
              margin: 0;

              &:first-of-type {
                border-left: none;
              }

              &:not(.actualites) {
                padding-left: 17px;
                padding-right: 13px;
                padding-top: 2px;
                font-size: rem(13);
              }
            }
          }
        }

        .centre {
          width: fit-content;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 0;

          .bouton {
            border-width: 2px;
            padding: 6px 24px;
          }

          h2 {
            width: 260px;
            text-align: center;
            padding-top: 16px !important;
            margin-top: auto !important;
            margin-bottom: auto !important;
            padding-left: 0 !important;
            margin-left: 0 !important;
            background-position-y: 0;
            background-position-x: center !important;
            background-size: contain !important;
            font-size: rem(36);
            color: #f2f0f2;
          }

          p {
            margin-bottom: 20px;
            width: calc(100% - 32px);

            &.textAnim {
              padding-right: 0;
            }
          }

          a {
            p {
              font-size: rem(15);
            }
          }
        }
      }

      &.devenir-membre {
        padding: 50px 20px;
        height: auto !important;

        .wrapper {
          max-width: 100%;

          img {
            width: 250px;
            height: auto;
          }

          h2 {
            margin: 20px 0;
            width: 100%;
            text-align: center;
          }

          div.contenu {
            flex-direction: column;
            margin: 40px 0;
            width: auto;

            ul, p {
              width: 100%;
              margin: 10px 0;
            }

            ul {
              li {
                margin: 20px 0;
                list-style: url("../images/crochet-liste-rouge.svg");
                &:first-child {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .accueil {
    header {
      .content {
        img.imgLogo {
          width: 70%;
          padding-right: 0 !important;
        }
      }
    }

    section.actualites .texte .centre, section.techniques .texte .centre, section.services .texte .centre {
      margin-right: 20px;

      h2 {
        padding: 0;
        margin: 0;
        background-size: contain;
        height: fit-content;
      }
    }
  }

}

@media screen and (max-width: 1500px) {
  .accueil {
    header {
      .content {
        img.imgLogo {
          padding-right: 15vw;
        }
      }
    }
  }
}

@media screen and (max-width: 497px) {
  .accueil {
    section.services {
      .fleches-nav {
        top: 323px;
      }
    }
  }
}

@media screen and (max-width: 322px) {
  .accueil {
    section.services {
      .fleches-nav {
        top: 360px;
      }
    }
  }
}

//Styles pour Edge
@supports (-ms-ime-align:auto) {

  @media screen and (max-width: 900px) {
    .accueil {

      section {
        div.texte {
          div.centre {
            width: 100%;
            padding-right: 15px;

            div.mobile {
              width: 100%;
            }

            h2 {
              position: absolute;
              height: 50%;
              top: 50%;
              left: 50%;
              bottom: -15%;
              transform: translate(-50%, -50%) !important;
            }
          }
        }
      }
    }

  }
}

//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .accueil {
    section {

      .fleches-nav{
        left: 0;
      }

      .texte {
        .centre {
          h2, p {
            max-width: 32vw;
          }
        }
      }

      &.services {
        .texte {
          .centre {
            h2 {
              max-width: 32vw;
            }
          }
        }
      }

      &.devenir-membre {
        .wrapper {

          div.contenu {

            p {
              max-width: 50vw;
            }
          }
        }
      }
    }
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 900px) {
  .accueil {
    section {

      .texte {

        .haut {
          z-index: 20;
          max-width: 48vw;

        }
      }
    }
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 900px) {
  .accueil {
    section {

      .texte {
        .centre {
          width: 100%;
          h2, p {
            max-width: 80vw;
          }
        }
      }
      img.imageSection{
        width: 100%;
      }
      &.actualites .texte .centre h2, &.techniques .texte .centre h2, &.services .texte .centre h2 {

        position: absolute;
        width: 100%;

        height: 50%;
        top: 50%;
        left: 50%;
        bottom: -15%;
        transform: translate(-50%, -50%) !important;
      }

      &.services {
        .texte {
          .haut {
            max-width: 90vw;
          }

          .centre {
            h2 {
              max-width: 80vw;
            }
          }
        }
      }

      &.devenir-membre {
        .wrapper {

          div.contenu {
            p {
              max-width: 90vw;
            }
          }
        }
      }
    }
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 880px) {
  .accueil {
    section {

      .texte {

        .centre {
          h2, p {
            max-width: 90vw;
          }
        }
      }

      &.services {
        .texte {
          .centre {
            h2 {
              max-width: 90vw;
            }
          }
        }
      }
    }
  }

}

//Styles pour Firefox
@-moz-document url-prefix() {
  @media screen and (max-width: 900px) {
    .accueil {
      section{

        &.actualites .texte .centre h2, &.techniques .texte .centre h2, &.services .texte .centre h2{
          position: absolute;
          height: 50%;
          top: 50%;
          left: 50%;
          bottom: -15%;
          transform: translate(-50%, -50%) !important;
        }

        .texte{
          .centre{
            width: 100%;
          }
        }

        img.imageSection{
          width: 90vw;
          max-width: 500px;
        }

      }


    }

  }

}


