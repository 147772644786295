.page-404 {
  background-color: $rouge-rose-lc;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: fit-content;

  & > img {
    position: absolute;
    height: 20vw;
    width: auto;

    &.gauchehaut {
      top: 0;
      left: 0;
      transform: scaleY(-1);
    }

    &.gauchebas {
      bottom: 0;
      left: 0;
    }

    &.droitehaut {
      top: 0;
      right: 0;
      transform: scale(-1);
    }

    &.droitebas {
      bottom: 0;
      right: 0;
      transform: scaleX(-1);
    }
  }

  div.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: auto;
    padding: 60px 0;

    img {
      z-index: 50;
    }

    h2 {
      text-align: center;
      width: fit-content;
      line-height: 95%;
    }

    h4 {
      text-align: center;
      width: fit-content;
      line-height: rem(22);
    }

    a.bouton {
      border: solid 3px $black-lc;
      padding: 8px 17px;
      cursor: pointer;
      display: flex;
      width: fit-content;
      transition: 0.3s all;
      margin-top: 34px;

      &:hover {
        background-color: $black-lc;
        border-color: $black-lc;
        text-decoration: none;

        p {
          color: $rouge-rose-lc;
        }
      }

      p {
        width: fit-content;
        margin: 0;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .page-404 {
    div.wrapper {
      width: calc(100vw - 20vw);
    }
  }
}

@media screen and (max-width: 500px) {
  .page-404 {
    & > img {
      height: 30vw;
    }

    div.wrapper {
      width: calc(100vw - 32px);
    }
  }
}

@media screen and (max-height: 880px) and (min-width: 900px) {
  .page-404 {
    overflow: hidden;
  }
}

//Styles pour Internet Explorer
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .page-404 {
    .modal{
      position: static;
    }

    div.wrapper{

      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 0;

      img{
        max-width: 85vw;
        width: auto;
      }

      h2, h4, a.bouton{
        max-width: 90vw;
      }
    }

  }
}

