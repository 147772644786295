/* Points de navigation */

.pg-pips {
  position:fixed;
  left:42.5px;
  font-family:$ff-titres;
  font-size:rem(18);
  letter-spacing:0.18rem;
  width:15px;
}

.pg-pips ul li a {
  width:100%;
  position:relative;
}

.pg-pips ul li a span {
  width:180px;
  position:absolute;
  margin-left:35px;
  margin-top:-6px;
  opacity:0;
  transition:0.3s all;
  pointer-events:none;
}

.pg-pips ul li:hover a span {
  opacity:1;
}

.pg-pips ul.points-fonce li a {
  background-color:transparent;
  border:solid 2px #ffffff;
  box-sizing:border-box;
}

.pg-pips ul.points-fonce li a.active {
  background-color:#ffffff;
}

.pg-pips ul.points-fonce li a span {
  color:#ffffff;
}

.pg-pips ul.points-fonce li:hover a {
  background-color:#ffffff;
}

.pg-pips ul.points-pale li a {
  background-color:transparent;
  border:solid 2px #2b2b2b;
  box-sizing:border-box;
}

.pg-pips ul.points-pale li a.active {
  background-color:#2b2b2b;
}

.pg-pips ul.points-pale li a span {
  color:#2b2b2b;
}

.pg-pips ul.points-pale li:hover a {
  background-color:#2b2b2b;
}

.pg-pips ul li {
  margin:0;
  margin-bottom:15px;
  height:15px;
  width:15px;
}

.pg-pips ul li:last-child {
  padding-bottom:0;
}

.pg-pips ul li:hover a {
  width:15px;
  height:15px;
  margin:0;
}

.pg-pips ul li a {
  transition:0.3s all;
  width:15px;
  height:15px;
  margin:0;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.pg-pips ul li a.active, .pg-pips ul li:hover a.active {
  width:15px;
  height:15px;
  margin:0;
  top:0;
  bottom:0;
  left:0;
  right:0;
}